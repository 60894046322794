<svelte:options immutable={true}/>
<script>
    import {getTagInfo} from '../../utils/API.mjs';
    import {chunk, eachSequential} from '../../utils/math/Array.mjs';
    import {linear} from '../../utils/math/sequence.mjs';
    import {CONTAINS_PARENTHESES_PAIRS} from '../../utils/regex.mjs';
    import {route} from '@bjornlu/svelte-router';
    import {fade} from 'svelte/transition';
    import {Link} from "@bjornlu/svelte-router";

    import ImageThumb from '../../fragments/ImageThumb.svelte';
    import CharacterTag from './CharacterTag.svelte';

    const isCharacterTag = CONTAINS_PARENTHESES_PAIRS.test(decodeURIComponent($route.params.tag));
    let loadedItems = [];

    const pload = (async ()=>{
        const info = await getTagInfo(decodeURIComponent($route.params.tag));
        eachSequential(50)(chunk(info.images, 6), vx=>requestAnimationFrame(()=>loadedItems = [...loadedItems, ...vx]));
        return info;
    })();
    const calculateDelay = linear({step: 50, initial: 250, limit: 1000});
</script>

<style>
    .c {
        grid-area: 1 / 1 / 2 / 2;
    }
    .o {
        grid-area: 2 / 1 / 3 / 2;
    }
</style>
<div style="overflow-y: scroll">
    <main class="row justify-content-center c">
        <article class="col m-5" style="max-width: 780px">
            {#if isCharacterTag}
                {#await pload}
                {:then data}
                    <CharacterTag hash="{data.image ?? data.images[0]?.hash}" description="{data.description}"/>
                {/await}
            {/if}
        </article>
    </main>

    <div class="p-0 o">
        <h3 class="text-center">Latest pictures {isCharacterTag?'featuring':'tagged with'} <strong class:character={isCharacterTag}>{decodeURIComponent($route.params.tag)}</strong></h3>
        {#await pload}
        {:then _}
            {#if loadedItems.length === 0}
                <p class="text-center mt-5 text-muted">There is nothing to see here</p>
            {:else}
                <div class="img-grid p-5">
                    {#each loadedItems as image, i (image.hash)}
                        <div in:fade={{delay: calculateDelay(i), duration: 250}}>
                            <ImageThumb {image}/>
                        </div>
                    {/each}
                </div>
            {/if}
        {/await}
        {#if loadedItems.length > 0}
            <Link class="row justify-content-center justify-self-end pb-3" to="/search/{decodeURIComponent($route.params.tag)}/0">
                <button in:fade={{delay: calculateDelay(loadedItems.length+1), duration: 250}} class="btn btn-success">View more</button>
            </Link>
        {/if}
    </div>
</div>