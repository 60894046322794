<svelte:options immutable={true}/>
<script>
    import {navigate} from '@bjornlu/svelte-router';
    import {MAX_ALBUM_NAME_LENGTH} from "../constants";
    import {patchAlbum, deleteAlbum} from '../utils/API.mjs';
    import shake from "../utils/animations/shake";

    import Modal from "../fragments/Modal.svelte";
    import ErrorModal from "./ErrorModal.svelte";
    import Fa from 'svelte-fa';
    import { faWifi, faTag, faImage } from '@fortawesome/free-solid-svg-icons';

    export let form;
    export let close;

    const originalName = form.name;

    const validateAlbumName = () => form.name.length < MAX_ALBUM_NAME_LENGTH;
    let showErrorModal;
    let errors = [];
    $: {
        errors = [];
        if (!form.name)
            errors.push('Album name is required');
        if (form?.name?.length > MAX_ALBUM_NAME_LENGTH)
            errors.push(`Album name must be shorter than ${MAX_ALBUM_NAME_LENGTH} characters`);
        errors = errors;
    }

    const handleSubmit = async ()=>{
        const response = await patchAlbum({name: form.name, poster: form.poster, oldName: originalName});
        if (response)
            close();
        else
            showErrorModal = true;
    }
    const handleDelete = async ()=>{
        const response = await deleteAlbum(originalName);
        if (response){
            navigate(-1);
            close();
        }
        else
            showErrorModal = true;
    }
</script>
<style>
    .input-group {
        border: 2px solid #1cc88a;
        border-radius: 4px;
    }
    .input-group-prepend {
        border-right: 2px solid #1cc88a;
    }
    .input-group-prepend.invalid {
        border: 0!important;
        border-right: 2px solid #e74c3c!important;
    }
    input {
        border: 0!important;
        border-radius: 0!important;
    }
    input:focus {
        border: inherit;
    }
    @media (min-width: 1200px) {
        .container-md {
            max-width: 642px;
        }
    }
</style>

<Modal bind:open={showErrorModal}>
    <ErrorModal description="There was an error while modifying the album" fix="Make sure there are no connectivity issues and try again">
        <Fa name="icon" icon={faWifi} size="5x" fw class="text-danger"/>
    </ErrorModal>
</Modal>
<div class="container-md p-5 shadow-lg bg-dark">
    <p class="text-muted">Do not change any field you don't want to modify</p>
    <form on:submit|preventDefault={handleSubmit}>
        <div class="form-group">
            <label for="name" class:required={!form.name}>Album name</label>
            <div class="input-group" class:invalid={!validateAlbumName(form.name)}>
                <div class="input-group-prepend" class:invalid={!validateAlbumName(form.name)}>
                    <div class="input-group-text">
                        <Fa name="icon" icon={faTag} fw/>
                    </div>
                </div>
                <input bind:value={form.name} class="form-control" id="name" placeholder="Beautiful Buildings" type="text">
            </div>
            <p class:text-warning={form.name?.length>MAX_ALBUM_NAME_LENGTH*0.9 && form.name?.length<=MAX_ALBUM_NAME_LENGTH} class:text-danger={form.name?.length > MAX_ALBUM_NAME_LENGTH} class:shake={form.name?.length > MAX_ALBUM_NAME_LENGTH} class="mb-4">{form.name?.length}/{MAX_ALBUM_NAME_LENGTH} characters</p>
        </div>

        <div class="form-group">
            <label for="poster" class="d-block">Cover image</label>
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        <Fa name="icon" icon={faImage} fw/>
                    </div>
                </div>
                <input bind:value={form.poster} autocomplete="off" class="form-control" id="poster" name="poster" placeholder={`Image id (/i/{id is here}})`} type="text">
            </div>
        </div>

        <div class="form-group">
            <label for="private" class="d-block">Private?</label>
            <div class="d-inline" style="cursor:pointer">
                <input type="checkbox" bind:checked={form.private} id="private">
            </div>
        </div>

        <div class="form-group d-flex justify-content-center">
            {#if errors.length !== 0}
                <button data-tooltip="{errors.join('\n')}" on:click={shake} class="btn btn-danger tooltip-error">Modify album</button>
            {:else}
                <button name="submit" type="submit" class="btn btn-success">Modify album</button>
            {/if}
        </div>
        <div class="form-group d-flex justify-content-center">
            <button name="submit" type="reset" class="btn btn-danger" on:click={handleDelete}>Delete album</button>
        </div>
    </form>
</div>