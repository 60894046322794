import sleep from '../sleep.mjs';

/**
 * @param {*[]} arr
 * @param {number} [size=1]
 * @return {*[][]}
 */
export const chunk = (arr, size=1) =>Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>arr.slice(i * size, i * size + size));

/**
 * @param {number} delay
 * @return function(*[], function(*): *): *
 */
export const eachSequential = (delay)=>(arr, fn)=>Promise.all(arr.map(async (x, i)=>{
    if (i>0)
        await sleep(delay*i);
    return fn(x);
}));