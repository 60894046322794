/**
 * @param {number} n
 * @param {{
 *     decimals: number=2
 * }} opts
 * @return {string}
 */
export default (n, opts = {decimals: 2})=>{
    if (n >= 1000000)
        return (n/1000).toFixed(opts.decimals)+'M';
    if (n >= 1000)
        return (n/1000).toFixed(opts.decimals)+'K';
    return n.toString();
}