<svelte:options immutable={true}/>
<script>
    import {Link, route} from '@bjornlu/svelte-router';
    import {AVATAR_STORAGE_DIR, USER_COLORS, AVATAR_MINI_SIZE} from '../constants.js';
    import {getImageLikes} from '../utils/API.mjs';
    import {slide} from "svelte/transition";
    import {iobserve} from "@shibiii/svelte-iobserve";

    let cursor;
    let likes;
    let scroller;
    $: hasMore = cursor && cursor !== '0';
    const pload = getImageLikes({hash: $route.params.hash, cursor}).then(x=>{
        cursor = x.cursor;
        likes = x.data;
        return x;
    });
    const loadMore = async ()=>{
        const more = getImageLikes({hash: $route.params.hash, cursor});
        likes = [...likes, ...more.data];
        cursor = more.cursor;
    };
</script>
<style>
    .avatar img{
        border: 2px white solid;
        border-radius: 25%;
    }
    h1 {
        font-family: "Helvetica Neue",serif;
        font-size: 2.1rem;
    }
</style>
<div class="container-md p-5 shadow-lg bg-dark h-100">
    <div class="row row-12 row-md-6 row-xl-4">
        <div class="col col-auto overflow-auto" style="max-height: 80vh" bind:this={scroller}>
            <h1>Users who liked this image</h1>
            {#await pload}
                <div class="spinner-border"></div>
            {:then users}
                {#if likes?.length === 0}
                    <p class="text-muted">Nobody liked this image</p>
                {/if}
                {#each likes as user, i (user.username)}
                    {#if i===likes.length-6 && hasMore}
                        <div class="row mb-3" let:this={tagged} on:intersection={loadMore} use:iobserve={{ once: true, options: {root: scroller, element: tagged, rootMargin: '150px'}}} transition:slide>
                            <div class="col-auto">
                            <span class="avatar">
                                <Link class="row" to="/u/{user.username}">
                                    <img crossorigin="anonymous" src="{AVATAR_STORAGE_DIR}/{user.username}_mini.webp" loading="lazy" class="rounded-circle mr-0" height="{AVATAR_MINI_SIZE}" width="{AVATAR_MINI_SIZE}" alt="{user.username}'s avatar">
                                    <p class="font-weight-bold my-auto ml-4 {USER_COLORS[user.color] ?? ''}">{user.username}</p>
                                </Link>
                            </span>
                            </div>
                        </div>
                    {:else}
                        <div class="row mb-3">
                            <div class="col-auto">
                            <span class="avatar">
                                <Link class="row" to="/u/{user.username}">
                                    <img crossorigin="anonymous" src="{AVATAR_STORAGE_DIR}/{user.username}_mini.webp" loading="lazy" class="rounded-circle mr-0" height="{AVATAR_MINI_SIZE}" width="{AVATAR_MINI_SIZE}" alt="{user.username}'s avatar">
                                    <p class="font-weight-bold my-auto ml-4 {USER_COLORS[user.color] ?? ''}">{user.username}</p>
                                </Link>
                            </span>
                            </div>
                        </div>
                    {/if}
                {/each}
            {/await}
        </div>
    </div>
</div>