<svelte:options immutable={true}/>
<script>
    import {onMount} from 'svelte';
    import {getDonationInfo} from '../../utils/API.mjs';
    import { iobserve } from '@shibiii/svelte-iobserve';
    import isVisible from '../../utils/isVisible.mjs';

    import {fade} from "svelte/transition";

    const pload = ()=>getDonationInfo();
    let render;
    let el;
    onMount(()=>render = isVisible(el));
</script>
<style>
    .donation-header {
        font-size: 1.2rem;
        font-weight: bold;
        color: var(--orange);
    }
    progress{
        width: 100%;
        -moz-appearance: none;
        border: none;
    }
    progress[value]::-webkit-progress-bar {
        background-color: #333;
        border-radius: 2px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
        border: none;
    }
    progress[value]::-moz-progress-bar {
        border-radius: 2px;
        background-color: #1cc88a;
        border: none;
    }
    progress[value]::-webkit-progress-value {
        background-color: #1cc88a;
        border-radius: 2px;
        background-size: 35px 20px, 100% 100%, 100% 100%;
        transition:width 1s linear
    }
    progress[value]::-moz-range-progress {
        background-color: #1cc88a;
        border-radius: 2px;
        background-size: 35px 20px, 100% 100%, 100% 100%;
        transition:width 1s linear
    }
</style>
<div data-nosnippet class="mt-auto mb-5" bind:this={el} on:intersection={()=>render = true} use:iobserve={{options: {once: true, root: this.parentElement, element: el, rootMargin: '450px'}}}>
    <header class="donation-header">Consider donating</header>
    {#if render}
        {#await pload()}
            <p>&nbsp;</p>
            <progress class="progress" max="100" value="0"></progress>
        {:then value}
            {#each value as x}
                <p in:fade>{x.name}</p>
                <progress title="{x?.progress.toFixed(2)}/{x?.cost.toFixed(2)}€" class="progress" max="100" value="{(x?.progress/x?.cost*100).toFixed(2)}"></progress>
            {/each}
        {:catch e}
            <progress class="progress" max="100" value="0"></progress>
        {/await}
        <small class="text-muted">*Progress is not updated in realtime</small>
        <form class="d-flex mt-3 justify-content-center" action="https://www.paypal.com/donate" method="post" target="_top">
            <input type="hidden" name="hosted_button_id" value="9RQMUJFQXTSMA" />
            <button class="btn btn-success">Donate</button>
        </form>
    {/if}
</div>
