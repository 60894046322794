<svelte:options immutable={true}/>
<script>
    import {Link, navigate} from '@bjornlu/svelte-router';
    import ClickOutside from '../utils/clickoutside.mjs';
    import {AVATAR_STORAGE_DIR, STORE_ACCOUNT_SCHEMA, AVATAR_MINI_SIZE} from '../constants';
    import {Account, WS, PMs, TopTags, Tags} from '../stores.mjs';
    import {slide} from 'svelte/transition';
    import Fa from 'svelte-fa';

    import RegisterModal from '../modals/RegisterModal.svelte';
    import ErrorModal from '../modals/ErrorModal.svelte';
    import TagInput from './TagInput.svelte';
    import Modal from './Modal.svelte';
    import {faBars, faEnvelope, faEnvelopeOpen, faWifi} from '@fortawesome/free-solid-svg-icons';
    import {faTwitter} from '@fortawesome/free-brands-svg-icons';
    import { faSearch } from '@fortawesome/free-solid-svg-icons';


    let isModalOpen;
    let isRegisterModalOpen;
    let isRegisterSelected;
    let isAccountDropdownOpen;
    let isNavbarExpanded;
    let isNotRegisteredModalOpen;
    let elHamburger;
    let vw;
    let searchTags = [];

    const handleLogout = ()=>$Account = STORE_ACCOUNT_SCHEMA;
    const navbarSlide = (node, args)=>{
        if (typeof args.cond === 'function')
            return args.cond() ? args.animation(node, args) : '';
        return args.cond ? args.animation(node, args) : '';
    };
    const handleSearch = ()=>{
        if (searchTags.length === 0)
            return;
        navigate(`/search/${searchTags.map(i=>i.trim()).sort().join('/')}/0`);
    };
    const hide = ()=>{
        isNavbarExpanded = false;
        isAccountDropdownOpen = false;
    }
    const loginChunk = async ()=>({
        UploadModal: (await import('../modals/UploadModal.svelte')).default,
    });
</script>
<style>
    .navbar-toggler {
        background-color: #1cc88a;
        padding-top: 8px;
        padding-bottom: 8px;
    }
    .nav-item {
        margin: auto;
        white-space: nowrap;
        align-items: center;
        cursor: pointer;    color: #00bc8c;
        text-decoration: none;
    }
    .nav-item:hover, .nav-item *:hover {
        color: #007053;
    }
    .avatar {
        border: 3px solid #1cc88a;
        height: 5vh;
        min-height: 46px;
        width: auto
    }
    ul {
        gap: 1rem;
    }
    @-webkit-keyframes fadeSlide {
        0% {
            opacity: 0;
            -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
            visibility: visible;
        }
        100% {
            opacity: 1;
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
    }
    @keyframes fadeSlide {
        0% {
            opacity: 0;
            -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
            visibility: visible;
        }
        100% {
            opacity: 1;
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
    }
</style>

<svelte:window bind:innerWidth={vw}/>

<Modal bind:open={isModalOpen} let:close={close}>
    {#await loginChunk() then {UploadModal}}
        <UploadModal close={close}/>
    {/await}
</Modal>
<Modal bind:open={isRegisterModalOpen} let:close={close}>
    <RegisterModal isRegisterSelected={isRegisterSelected} close={close}/>
</Modal>
<Modal bind:open={isNotRegisteredModalOpen}>
    <ErrorModal description="You need to register first"/>
</Modal>

<header class="navbar navbar-expand-lg py-2" on:click={hide} use:ClickOutside={{cond: ()=>isNavbarExpanded}} on:clickOutside={hide}>
    <Link class="navbar-brand mx-md-5" to="/">
        Waifu.gallery
    </Link>

    <button class="navbar-toggler ml-auto" type="button" bind:this={elHamburger} on:click|stopPropagation={()=>isNavbarExpanded = !isNavbarExpanded} aria-label="Toggle navigation">
        <Fa icon={faBars} fw primaryColor="#1a1a1a"/>
    </button>
    {#if isNavbarExpanded || vw>=992}
        <nav transition:navbarSlide={{cond: vw<992, animation: slide}} class="navbar-collapse navbar-collapse-sm">
            <ul class="navbar-nav m-3">
                <li class="nav-item" on:click|stopPropagation>
                    <form class="align-items-center row flex-nowrap" class:d-block={isNavbarExpanded} on:submit|preventDefault={handleSearch}>
                        <label for="search" class="m-2 sr-only">Search</label>
                        <TagInput addKeys={[9, 188]}
                                  tags={searchTags}
                                  maxTags={5}
                                  col={isNavbarExpanded}
                                  allowPaste={true}
                                  allowDrop={true}
                                  splitWith={","}
                                  onlyUnique={true}
                                  removeKeys={[27]}
                                  placeholder={$TopTags.slice(0,2).map(t=>t.name).join(', ')}
                                  autoComplete={$Tags}
                                  name={"search"}
                                  id={"search"}
                                  allowBlur={true}
                                  minChars={2}
                        />
                        <button type="submit" aria-label="search" class="btn btn-primary" class:ml-2={!isNavbarExpanded} class:mt-1={isNavbarExpanded} class:btn-block={isNavbarExpanded}>
                            <Fa icon={faSearch} fw/>
                        </button>
                    </form>
                </li>
                {#if $Account.username}
                    <li class="nav-item" on:click={()=>isModalOpen = true}>
                        Upload
                    </li>
                {:else}
                    <li class="nav-item text-muted" on:click={()=>isNotRegisteredModalOpen = true}>
                        Upload
                    </li>
                {/if}
                <li class="nav-item">
                    <Link to="/ToS">ToS</Link>
                </li>
                <li class="nav-item">
                    <a href="mailto:gallerywaifu@gmail.com">Contact/DMCA</a>
                </li>
                <!--
                <li class="nav-item">
                    <Link to="/FAQ">FAQ</Link>
                </li>
                -->
            </ul>
            <ul class="navbar-nav ml-auto list-unstyled">
                {#if $Account.username}
                    <li class="nav-item dropdown" on:click|stopPropagation={()=>isAccountDropdownOpen = !isAccountDropdownOpen} use:ClickOutside on:clickOutside={()=>isAccountDropdownOpen = false}>
                        <img height="{AVATAR_MINI_SIZE}" width="{AVATAR_MINI_SIZE}" crossorigin="anonymous" src="{`${AVATAR_STORAGE_DIR}/${$Account.username}_mini.webp`}" class="rounded-circle avatar pointer" alt="Your avatar">
                        {#if isAccountDropdownOpen}
                            <nav transition:slide class="dropdown-menu">
                                <Link to="{`/u/${$Account.username}`}" class="dropdown-item">
                                    Account
                                </Link>
                                <Link to="/pms" class="dropdown-item">
                                    Mail
                                    {#if $PMs > 0}
                                        <Fa icon={faEnvelope} fw class="text-muted ml-1"/>
                                    {:else}
                                        <Fa icon={faEnvelopeOpen} fw class="ml-1"/>
                                    {/if}
                                </Link>
                                <p class="dropdown-item text-danger" on:click={handleLogout}>Logout</p>
                            </nav>
                        {/if}
                    </li>
                {:else}
                    <li class="nav-item" on:click={()=>isRegisterModalOpen=true}>
                        Register/Login
                    </li>
                {/if}
                {#if $Account?.username}
                    <li class="nav-item">
                        <Link to="/feedback" class="text-warning">
                            Feedback/bugs
                        </Link>
                    </li>
                {/if}
                <li class="nav-item" on:click={()=>alert("Coming soon!")}>
                    <Fa icon={faTwitter} fw/>
                </li>
            </ul>
        </nav>
    {/if}
    {#if $Account?.opts?.ws?.enabled}
        <div class="px-3 row align-items-end d-none d-md-block">
            <Fa icon={faWifi} fw class="{!Object.values($WS.subscriptions).some(v=>v)?'text-danger':''}"/>
        </div>
    {/if}
</header>