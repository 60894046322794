<svelte:options immutable={true}/>
<script>
    import TagList from './TagList.svelte';
    import {Account} from '../../stores.mjs';

    import Donations from './Donations.svelte';
    import Navigation from './Navigation.svelte';

    export let imageTags;
</script>

<aside class="col-auto pl-5 pr-5 d-none d-md-flex bg-dark flex-column">
    {#if $Account.username}
        <Navigation/>
    {/if}
    <TagList {imageTags}/>
    <Donations/>
</aside>