<svelte:options immutable={true}/>
<script>
    export let description = "An (still) undocumented error has ocurred";
    export let fix = false;

    import Fa from 'svelte-fa';
    import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
</script>
<style>
    @media (min-width: 1200px) {
        .container-md {
            max-width: 642px;
        }
    }
    strong {
        font-weight: normal;
    }
</style>
<div class="container-md p-5 shadow-lg bg-dark d-flex justify-content-center">
    <slot name="icon">
        <Fa icon={faExclamationTriangle} size="5x" fw class="text-danger"/>
    </slot>
    <div class="col">
        <h3>There was an error</h3>
        <p>Error description: <strong class="text-warning">{description}</strong></p>
        {#if fix}
            <p>Possible solution: {fix}</p>
        {/if}
    </div>
</div>