<svelte:options immutable={false}/>
<script>
    import partitionTags from '../utils/partitionTags.mjs';
    import timeAgo from '../utils/timeAgo.mjs';
    import {route, Link} from '@bjornlu/svelte-router';
    import {IMAGE_ROUTE_PREFIX, AVATAR_STORAGE_DIR, AVATAR_MINI_SIZE, USER_COLORS, BREAKPOINTS} from '../constants.js';
    import {Account, Head} from '../stores.mjs';
    import {getImageMeta, likeImage, postTopLevelComment} from '../utils/API.mjs';
    import unitPrefix from '../utils/unitPrefix.mjs';
    import {slide, scale} from "svelte/transition";
    import {linear} from '../utils/math/sequence.mjs';
    import {MATCH_UP_TO_FIRST_PARENTHESE} from '../utils/regex.mjs';

    import WhoLikedModal from '../modals/WhoLikedModal.svelte';
    import Comment from './Comment.svelte';
    import Sidebar from '../fragments/Sidebar/Sidebar.svelte';
    import Modal from '../fragments/Modal.svelte';
    import Fa from 'svelte-fa';
    import { faExclamationTriangle, faImages, faTag, faCertificate, faClock, faHeart, faGlobe, faPalette, faComments, faEye } from '@fortawesome/free-solid-svg-icons';

    const loginChunk = async ()=>({
        AddToAlbumModal: (await import('../modals/AddToAlbumModal.svelte')).default,
        ReportModal: (await import('../modals/ReportModal.svelte')).default,
        AddTagModal: (await import("../modals/ImageMetaUpdateModal.svelte")).default
    });

    /** @type {boolean}*/
    let likestate;
    /** @type {string} */
    let topLevelTextarea = '';
    /** @type {Object[]} */
    let createdComments = [];
    /** @type {boolean} */
    let shouldLikeAnimationPlay;
    let likeAnimationTimeout;
    /** @type HTMLElement */
    let imgEl;
    /** @type {number} */
    let vw;
    /** @type {string} */
    let imgAlt;

    const pload = getImageMeta($route.params.hash).then(image=>{
        likestate = image.haveILiked;
        imgAlt = `${image.title ? `${image.title} - ${partitionTags(image.tags).others.join(', ')}` : partitionTags(image.tags).characters.join(', ')}`;
        if (image.tags.includes('nsfw'))
            imgAlt+=' hentai';
        const tagString = [...new Set(image.tags)].join(', ');
        const titleString = image?.title || partitionTags(image.tags).characters.map(x=>x.match(MATCH_UP_TO_FIRST_PARENTHESE)[0].trimRight()).reduce((acc, cur, i, arr)=>{
            if (i===arr.length-1)
                return `${acc} and ${cur}`;
            return `${acc}, ${cur}`;
        });
        Head.set({
            canonical: window.location.href,
            title: `${titleString} by ${image?.artist?.nickname ?? image.uploader.username}`,
            meta: [
                {name: 'description', content: `${image.title} (${tagString})`},
                {name: 'keywords', content: tagString},
                {name: 'author', content: image.uploader.username},
                {name: 'rating', content: image.tags.map(x=>x.toUpperCase()).includes('NSFW') ? 'adult' : 'general'},
                {name: 'twitter:card', content: 'summary_large_image'},
                {name: 'twitter:title', content: `${image.title} by ${image.uploader.username}`},
                {name: 'twitter:image', content: `${IMAGE_ROUTE_PREFIX}/${$route.params.hash}.webp`},
                {name: 'og:type', content: 'article'},
                {name: 'og:title', content: image.title},
                {name: 'og:image', content: `${IMAGE_ROUTE_PREFIX}/${$route.params.hash}.webp`},
                {name: 'og:image:type', content: 'image/webp'}
            ]
            .concat(image.title ? [
                {name: 'twitter:image:alt', content: image.title}
            ] : [])
            .concat(image.text ? [
                {name: 'twitter:description', content: image.text},
                {name: 'og:description', content: image.text}
            ] : [])
        });
        return image;
    });

    const calculateSlideDelay = linear({step: 75});

    async function handleLike(){
        likestate = !likestate;
        shouldLikeAnimationPlay = likestate;
        clearTimeout(likeAnimationTimeout);
        setTimeout(()=>shouldLikeAnimationPlay=false, 3250);
        await likeImage($route.params.hash);
    }

    const handleTopLevelComment = async ()=>{
        const id = postTopLevelComment({image: $route.params.hash, content: topLevelTextarea});
        if (id)
            createdComments = [...createdComments, {
                id,
                createdAt: (new Date()).toISOString(),
                content: topLevelTextarea,
                modified: false,
                author: {username: $Account.username}
            }];
        topLevelTextarea = '';
    };
    let isAddToAlbumModalOpen;
    let isReportModalOpen;
    let isLikesModalOpen;
    let isAddTagModalOpen;
</script>
<style>
    .hero {
        overflow-x: hidden;
    }

    .reflection {
        -webkit-transform: scaleY(-1);
        -moz-transform: scaleY(-1);
        -ms-transform: scaleY(-1);
        -o-transform: scaleY(-1);
        transform: scaleY(-1);
    }

    .reflection>* {
        -webkit-transform: scaleY(-1);
        -moz-transform: scaleY(-1);
        -ms-transform: scaleY(-1);
        -o-transform: scaleY(-1);
        transform: scaleY(-1);
    }

    .reflection::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: .05;
        z-index: -1;
        overflow: hidden;
        background: var(--bg-url);
        background-position: bottom;
        background-size: cover;
    }

    .post-img {
        max-width: 100%;
        max-height: calc(100vh - 92px);
        height: auto;
        width: auto;
    }

    .cardbox {
        border-radius: 3px;
        margin-bottom: 20px;
        padding: 0!important;
        width: fit-content;
        width: -moz-fit-content;
        max-width: 100%;
    }

    .cardbox .cardbox-heading {
        padding: 16px;
    }

    .media {
        display: flex;
        align-items: flex-start;
    }
    .media img, .media .spinner-wrapper{
        width: 48px !important;
        height: 48px !important;
        padding: 2px;
        border: 2px solid #f4f4f4;
    }
    .media-body {
        flex: 1;
        padding: .4rem !important;
    }
    .media-body small{
        font-family: 'Rokkitt', serif;
        font-size: 12px;
        color: #aaa;
        margin-right: 10px;
    }

    .cardbox-base {
        border-bottom: 2px solid #f4f4f4;
    }

    .cardbox-base ul{
        margin: 10px 0 10px 15px!important;
        padding: 10px !important;
        display: inline-block;
    }

    .cardbox-base li {
        list-style: none;
        display: inline-block;
    }

    .cardbox-base li * {
        margin: auto;
    }

    .cardbox-comments{
        padding: 10px 40px 20px 40px !important;
        text-align: center;
        width: 100%;
        background-color: #222;
    }
    .cardbox-comments .comment-avatar img{
        margin-top: 1px;
        margin-right: 10px;
        position: relative;
        display: inline-block;
        text-align: center;
        width: 40px;
        height: 40px;
    }
    textarea {
        flex-grow: 1;
        min-height: 8rem;
    }
    textarea::placeholder{
        font-weight: bold;
        font-size: 1.1rem;
    }
</style>

<svelte:window bind:innerWidth={vw}/>
{#if $Account.username}
    <Modal bind:open={isAddToAlbumModalOpen} let:close={close}>
        {#await loginChunk() then {AddToAlbumModal}}
            <AddToAlbumModal hash={$route.params.hash} close={close}/>
        {/await}
    </Modal>
    <Modal bind:open={isReportModalOpen} let:close={close}>
        {#await loginChunk() then {AddToAlbumModal}}
            <AddToAlbumModal type="Image" contentId={$route.params.hash} close={close}/>
        {/await}
    </Modal>
    <Modal bind:open={isLikesModalOpen} let:close={close}>
        <WhoLikedModal close={close}/>
    </Modal>
    {#await pload}
    {:then image}
        {#await loginChunk() then {AddTagModal}}
            <Modal bind:open={isAddTagModalOpen} let:close={close}>
                <AddTagModal image={{hash: $route.params.hash, ...image}} close={close}/>
            </Modal>
        {/await}
    {/await}
{/if}

<div class="row vh-100" style="overflow-y: hidden">
    {#await pload}
        <Sidebar imageTags={[]}/>
    {:then image}
        <Sidebar imageTags={image.tags}/>
    {/await}
    <main class="hero px-md-3 py-2 py-md-4 justify-content-center mx-auto position-relative" style="flex: 1; --bg-url:{`url(https://api.waifu.gallery/image/${$route.params.hash}.webp)`}; max-height: 100%; overflow-y: auto">
        <div class="cardbox mx-auto shadow-lg">
            <div class="cardbox-item d-flex flex-column justify-content-center">
                <div class="cardbox-heading">
                    {#if $Account.username}
                        <div class="float-right">
                            <button class="btn btn-danger tooltip-error" type="button" aria-label="report" data-tooltip="Report" on:click={()=>isReportModalOpen = true}>
                                <Fa icon={faExclamationTriangle} fw/>
                            </button>
                            <button class="btn btn-success" on:click={()=>isAddToAlbumModalOpen = true} type="button" aria-label="add to album" data-tooltip="Add to album">
                                <Fa icon={faImages} fw/>
                            </button>
                            <button class="btn btn-success d-none d-sm-inline-block" on:click={()=>isAddTagModalOpen = true} type="button" aria-label="edit" data-tooltip="Edit">
                                <Fa icon={faTag} fw/>
                            </button>
                        </div>
                    {/if}
                    <div class="media m-0">
                        <div class="d-flex mr-2">
                            {#await pload}
                                <div class="spinner-wrapper rounded-circle d-flex justify-content-center align-items-center p-0">
                                    <div class="spinner-grow w-100 h-100 spinner-dark"></div>
                                </div>
                            {:then image}
                                <Link to="/u/{image.uploader.username}">
                                    <img crossorigin="anonymous" class="rounded-circle" src="{AVATAR_STORAGE_DIR}/{image.uploader.username}_mini.webp" height="{AVATAR_MINI_SIZE}" width="{AVATAR_MINI_SIZE}" alt="{image.uploader.username}'s avatar">
                                </Link>
                            {/await}
                        </div>
                        <div class="media-body">
                            {#await pload}
                            {:then image}
                                <Link class="font-weight-bold d-block {`m-0 ${USER_COLORS[image.uploader.color] ?? ''}`}" to="/u/{image.uploader.username}" rel="author" style="width: min-content;">
                                    {image.uploader.username}
                                </Link>
                                <div class="d-flex flex-wrap">
                                    <small><Fa icon={faCertificate} fw/>&nbsp;{unitPrefix(image.uploader.profileViews)}</small>
                                    <small><Fa icon={faClock} fw/>&nbsp;<time>{timeAgo(Date.parse(image.createdAt))}</time></small>
                                </div>
                            {/await}
                        </div>
                    </div>
                </div>

                <picture>
                    <source type="image/webp" srcset="{IMAGE_ROUTE_PREFIX}/{$route.params.hash}.webp"/>
                    <img width="auto" height="100%" crossorigin="anonymous" class="post-img" bind:this={imgEl} on:dblclick={handleLike} src="{IMAGE_ROUTE_PREFIX}/{$route.params.hash}.webp" alt="{imgAlt}">
                </picture>

                {#if shouldLikeAnimationPlay}
                    <span out:scale={{duration: 550}} class="heart">
                        <Fa icon={faHeart} size="20x" fw/>
                    </span>
                {/if}

                <section class="cardbox-base bg-dark reflection" >
                    <ul class="row justify-content-center">
                        <li on:click={handleLike}>
                            <Fa icon={faHeart} class="fa fa-heart {`${likestate?'text-danger':'text-muted'}`} pointer" fw style="{likestate?'filter: drop-shadow(0px 8px 4px rgba(231, 76, 60, 0.2))':''}"/>
                        </li>

                        {#await pload}
                            <li class="ml-2">
                                <div class="row">
                                    <div class="spinner-border spinner-border-sm m-auto"></div>
                                    <p class="text-muted ml-1">Likes</p>
                                </div>
                            </li>

                            <li class="ml-2">
                                <div class="d-flex align-items-center">
                                    <Fa icon={faEye} class="text-muted" fw/>
                                    <div class="spinner-border spinner-border-sm ml-2"></div>
                                </div>
                            </li>
                        {:then image}
                            <li>
                                <div class="row pointer" on:click={()=>isLikesModalOpen = true}>
                                    <p class="text-muted ml-2">{unitPrefix(image.likeCount - (image.haveILiked && !likestate))} Likes</p>
                                </div>
                            </li>

                            <li class="ml-2">
                                <div class="d-flex align-items-center">
                                    <Fa icon={faEye} class="text-muted" fw/>
                                    <p class="text-muted ml-2">{unitPrefix(image.imageViews)}</p>
                                </div>
                            </li>
                        {/await}

                    </ul>
                    <ul class="float-right">
                        <li><Fa icon={faComments} fw/></li>
                        {#await pload}
                            <li><em class="mr-4 spinner-border spinner-border-sm"></em></li>
                        {:then image}
                            <li><em class:mr-4={!image.source} class="ml-2">{unitPrefix(image.commentCount+createdComments.length)}</em></li>
                            {#if image?.artist?.nickname}
                                <li class="pl-2 d-none d-sm-inline-block" class:d-none={vw<BREAKPOINTS.MD} class:pointer={image?.artist?.website} data-tooltip="{image.artist.nickname}">
                                    {#if image?.artist?.website}
                                        <a href="{image.artist.website}" target="_blank">
                                            <Fa icon={faPalette} fw/>
                                        </a>
                                    {:else}
                                        <Fa icon={faPalette} fw class="text-muted"/>
                                    {/if}
                                </li>
                            {/if}
                            {#if image.source}
                                <li class="pl-2 mr-4" class:pr-4={!image?.artist?.nickname}>
                                    <a href="{image.source}" target="_blank" rel="noopener" aria-label="artist">
                                        <Fa icon={faGlobe} fw/>
                                    </a>
                                </li>
                            {/if}

                        {/await}
                    </ul>
                </section>
            </div>

            {#await pload}
            {:then image}
                {#if image.text}
                    <caption class="cardbox-base bg-dark p-5 text-muted d-block">{image.text}</caption>
                {/if}
            {/await}

            {#if $Account.username}
                <section class="cardbox-comments">
                    <span class="comment-avatar float-left">
                        <img crossorigin="anonymous" class="rounded-circle" src="{AVATAR_STORAGE_DIR}/{$Account.username}_mini.webp" width={AVATAR_MINI_SIZE} height={AVATAR_MINI_SIZE} alt="Your avatar">
                    </span>
                    <form class="form-inline" on:submit|preventDefault={handleTopLevelComment} style="gap: 1rem">
                        <label class="sr-only" for="comment">Comment</label>
                        <textarea bind:value={topLevelTextarea} id="comment" placeholder="Write a comment"></textarea>
                        <button class="btn btn-success btn-block" type="submit" style="color: #1a1a1a">Comment</button>
                    </form>
                </section>
            {/if}

            {#await pload}
            {:then image}
                {#each createdComments.concat(image.comments ?? []) as comment, i}
                    <div in:slide={{delay: calculateSlideDelay(i)}}>
                        <Comment {comment}/>
                    </div>
                {/each}
            {/await}

        </div>
    </main>
</div>