<svelte:options immutable={true}/>
<script>
    import {getContext} from 'svelte';
    import ImageThumb from "../fragments/ImageThumb.svelte";
    import {fade} from "svelte/transition";

    const user = getContext("props").getUser();
    const calculateDelay = i=>Math.min(150+50*i, 550);
</script>
<section class="tab-pane active">
    <div class="row">
        {#if user?.images?.map(i=>({...i, uploader: {...user, username: user.username}}))?.length===0}
            <p class="text-muted">This user does not have any image</p>
        {:else}
            {#each user?.images?.map(i=>({...i, uploader: {...user, username: user.username}})) as image, i}
                <div in:fade={{delay: calculateDelay(i)}} class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4 p-0 p-md-2 p-lg-3">
                    <ImageThumb {image}/>
                </div>
            {/each}
        {/if}
    </div>
</section>