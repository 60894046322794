import {writable, readable} from "svelte/store";
import {getGallery, getTopTags, getTags} from "./utils/API.mjs";
import {route} from "@bjornlu/svelte-router";
import {FAVICON_URL, STORE_ACCOUNT_SCHEMA, WS_PERSISTENT_CHANNELS, FAVICON_SIZE} from './constants.js';
import {chunk, eachSequential} from './utils/math/Array.mjs';
const state = {
    TopTags: {
        set: false
    },
    Gallery: {
        set: false,
        ws: undefined
    }
}

const Tags = writable([], set=>{
    getTags().then(t=>set(t));
    return ()=>{};
});
const WS = writable({subscriptions: {gallery: false}});
const Account = writable({...JSON.parse(localStorage.getItem("Account") ?? JSON.stringify(STORE_ACCOUNT_SCHEMA))});

const TopTags = readable([], set=> {
    if (!state.TopTags.set){
        state.TopTags.set = true;
        getTopTags().then(t=>set(t.sort((a, b)=>a.occurrences>b.occurrences?-1:1)));
    }
    return ()=>{};
});

const Gallery = writable([], set=>{
    if (!state.Gallery.set){
        getGallery().then(g=>{
            set(g.data);
            Gallery.cursor = g.cursor;
        });
        state.Gallery.set = true;
    }
    return ()=>{};
});

/**
 * @param {string[]} [keep=[]]
 * @return void
 */
const closeUnusedWs = (keep=[])=>{
    keep = keep.concat(WS_PERSISTENT_CHANNELS);
    WS.update(val=>{
        Object.keys(val.subscriptions).filter(k=>!keep.includes(k)).forEach(k=>{
            if (val.subscriptions[k]) {
                try {
                    val.subscriptions[k].close();
                } catch (e) {}
                val.subscriptions[k] = false;
            }
        });
        return val;
    });
}

Account.subscribe(val => localStorage.setItem("Account", JSON.stringify(val)));

const unsubWS = Account.subscribe(val=>{
    if (val?.opts?.ws?.enabled){
        route.subscribe(val=>{
            const routeName = val.matched?.[0]?.name;
            switch (routeName){
                case "GALLERY": {
                    WS.update(val=>{
                        const ret = {subscriptions: {}};
                        if (val?.subscriptions?.gallery === false) {
                            ret.subscriptions.gallery = new WebSocket("wss://api.waifu.gallery/ws/gallery");
                            ret.subscriptions.gallery.onopen = e=>{
                                console.info("Opened ws connection to /ws/gallery");
                                setInterval(()=>{try{ret.subscriptions.gallery?.send("ping")}catch{}}, 60000);
                                ret.subscriptions.gallery.onmessage = event => Gallery.update(old => [JSON.parse(event.data)].concat(old));
                            }
                            ret.subscriptions.gallery.onclose = e=>{
                                console.info("Closed ws connection to /ws/gallery")
                                WS.update(t=>({...t, subscriptions: {...t.subscriptions, gallery: false}}));
                            }
                            ret.subscriptions.gallery.onerror = ()=>{
                                console.info("gerror");
                            };
                        }
                        closeUnusedWs(["gallery"]);
                        return {...val, subscriptions: {...val.subscriptions, ...ret.subscriptions}};
                    });
                    break;
                }
                default: {
                    closeUnusedWs();
                }
            }
        });
        setTimeout(()=>unsubWS()); //Work done, the roune now have a subscription
    }
});

const PMs = writable(0);

const unsubPms = Account.subscribe( accval=>{
    if (accval?.username && accval?.jwt && accval?.opts?.ws?.enabled) {
        WS.update(wsval=>{
            let ret = {subscriptions: {}};
            ret.subscriptions.pms = new WebSocket(`wss://api.waifu.gallery/ws/pms?Bearer=${accval.jwt.substr(7)}`);
            ret.subscriptions.pms.onopen = e => {
                console.info("Opened ws connection to /ws/pms");
                setInterval(() => {try{wsval.subscriptions.pms?.send("ping")}catch{}}, 60000);
                ret.subscriptions.pms.onmessage = event => {
                    if (event.data!=="ping")
                        PMs.update(t=>t+1);
                };
            }
            ret.subscriptions.pms.onclose = e => {
                setTimeout(()=>WS.update(t=>({...t, subscriptions: {...t.subscriptions, pms: false}})));
                console.info("Closed ws connection to /ws/pms")
            }
            ret.subscriptions.pms.onerror = ret.subscriptions.pms.onclose;
            return {...wsval, subscriptions: {...wsval.subscriptions, ...ret.subscriptions}};
        });
        setTimeout(()=>unsubPms());
    }
});
const headSchema = {
    title: 'Waifu gallery - A new kind of imageboard',
    canonical: undefined,
    ld: [],
    alternate: {
        url: window.location.href,
        hreflang: "en"
    },
    icon: {
        mimetype: "icon/png",
        size: FAVICON_SIZE,
        url: FAVICON_URL
    },
    meta: [
        {name: 'robots', content: 'follow,archive,snippet'},
        {name: 'keywords', content: "catgirl, nsfw, sfw, images, lewd, neko, anime, trap, waifu, pics, gallery"},
        {
            name: "description",
            content: "Upload, view, save, create albums and share the images you like"
        },
        {
            name: "rating",
            content: "General"
        },
        {
            name: "revisit-after",
            content: "7 days"
        },
        {
            name: "theme-color",
            content: "#1a1a1a"
        },
        {
            name: "google",
            content: "nositelinkssearchbox"
        },
        {
            name: "google",
            content: "notranslate"
        },
        {
            name: "twitter:title",
            content: "Waifu gallery"
        },
        {
            name: "twitter:description",
            content: "Upload, view, save and share the images you like"
        },
        {
            name: "twitter:site",
            content: "@kran6a"
        },
        {
            name: "twitter:card",
            content: "summary"
        },
        {
            name: "twitter:image",
            content: FAVICON_URL
        },
        {
            name: "twitter:creator",
            content: "@kran6a"
        },
        {
            name: "twitter:domain",
            content: window.location.hostname
        },
        {
            name: "og:site_name",
            content: "Waifu gallery"
        },
        {
            name: "og_title",
            content: "Waifu gallery"
        },
        {
            name: "og_description",
            content: "Upload, view, save and share the images you like"
        },
        {
            name: "og:type",
            content: "website"
        },
        {
            name: "og:image",
            content: FAVICON_URL
        },
        {
            name: "og:image:type",
            content: "image/webp"
        },
        {
            name: "og:image:width",
            content: "600"
        },
        {
            name: "og:image:height",
            content: "600"
        }
    ]
}
const Head = writable(headSchema);
Head.reset = Head.set.bind(null, headSchema);
const hashes = new Set();
Gallery.fetchMore = async ()=>{
    const fetchedImages = await getGallery(Gallery.cursor);
    Gallery.cursor = fetchedImages.cursor;
    return eachSequential(50)(chunk(fetchedImages.data.filter(i=>!hashes.has(i.hash)), 6), vx=>requestAnimationFrame(()=>Gallery.update(val=>[...val, ...vx])));
}

const schemas = {
    head: headSchema
}

export {Account, TopTags, Gallery, WS, Tags, PMs, Head, schemas, state as STATE};