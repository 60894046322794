<svelte:options immutable={true}/>
<script>
    import {getContext} from 'svelte';
    import {THUMBNAIL_ROUTE_PREFIX, THUMBNAIL_SIZES} from "../constants";
    import {Account} from "../stores";
    import {Link} from "@bjornlu/svelte-router";

    import CreateAlbumModal from "../modals/CreateAlbumModal.svelte";
    import Modal from "../fragments/Modal.svelte";
    import Fa from 'svelte-fa';
    import { faFolderPlus } from '@fortawesome/free-solid-svg-icons';


    const {albums, username} = getContext("props").getUser();
    let createdAlbums = [];
    const genSrcset = hash=>THUMBNAIL_SIZES.map(s=>`${THUMBNAIL_ROUTE_PREFIX}/${hash}_${s}x${s}.webp ${s}w`).join(', \n');
    /** @type {boolean} */
    let isModalOpen;
</script>
<style>
    img {
        background-color: transparent;
    }
    h1{
        font-family: "Helvetica Neue",serif;
    }
    .stack {
        cursor: pointer;
        position: relative;
        z-index: 10;
        margin-bottom: 20px;
        display: inline-block;
    }

    .stack img {
        background: #111;
        -moz-box-sizing: content-box;
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
        padding: 10px;
        width: 100%;
        -moz-box-shadow:    1px 1px 2px rgba(87, 87, 87, 0.25);
        -webkit-box-shadow: 1px 1px 2px rgba(87, 87, 87, 0.25);
        box-shadow:         1px 1px 2px rgba(87, 87, 87, 0.25);
    }

    .stack:before {
        background: #111;
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -10;
        -webkit-transform: rotate(-2.75deg);
        -moz-transform: rotate(-2.75deg);
        -o-transform: rotate(-2.75deg);
        -moz-box-shadow:    1px 1px 2px rgba(87, 87, 87, 0.25);
        -webkit-box-shadow: 1px 1px 2px rgba(87, 87, 87, 0.25);
        box-shadow:         1px 1px 2px rgba(87, 87, 87, 0.25);
    }
</style>
<Modal bind:open={isModalOpen} let:close={close}>
    <CreateAlbumModal close={album=>{createdAlbums = [...createdAlbums, album]; close()}}/>
</Modal>

{#if albums.concat(createdAlbums).length !== 0}
    <section class="row row-cols-1 row-cols-sm-2 row-cols-md-3 justify-content-around" style="gap: 32px">
        {#each albums.concat(createdAlbums) as album}
            <div class="stack">
                <Link to="/a/{album.id}">
                    {#if album?.poster ?? album?.images?.length > 0}
                        <img height="auto" width="100%" crossorigin="anonymous" srcset="{genSrcset(album?.poster?.hash ?? album.images?.[0].hash)}" alt="Album poster" sizes="(max-width: 320px) 128px, (max-width: 1920px) 256px, 512px">
                    {:else}
                        <img height="auto" width="100%" crossorigin="anonymous" src="{`${THUMBNAIL_ROUTE_PREFIX}/album_placeholder.webp`}" alt="Default poster" />
                    {/if}
                </Link>
                <h1 class="text-center py-2">{album.name}</h1>
            </div>
        {/each}
        {#if $Account.username === username}
            <div class="stack p-5 col-3 ml-auto justify-content-center d-flex" on:click={()=>isModalOpen = true}>
                <div class="d-flex align-items-center">
                    <Fa icon={faFolderPlus} class="pr-1"/>
                    <p>New album</p>
                </div>
            </div>
        {/if}
    </section>
{:else if $Account.username === username}
    <section class="stack p-5 justify-content-center mr-3 ml-3" style="cursor: pointer" on:click={()=>isModalOpen = true}>
        <div class="d-flex align-items-center">
            <Fa icon={faFolderPlus} class="pr-1"/>
            <p>New album</p>
        </div>
    </section>
    <p class="text-muted">You have no albums</p>
{:else}
    <p class="text-muted">This user has no albums</p>
{/if}
