import {API_URL} from "../constants.js";

/**
 * @param {RequestInfo} url
 * @param {RequestInit} [opts={}]
 * @return Promise<Response>
 */
const jwtRequest = (url, opts = {})=>{
    const {jwt} = JSON.parse(localStorage.getItem("Account"));
    if (!jwt)
        throw new Error("JWT not set");
    return fetch(url, {...opts, headers: {...(opts.headers ?? {}), authorization: jwt}});
}
/**
 * @param {RequestInfo} url
 * @param {RequestInit} opts
 * @return {Promise<Response>}
 */
const optionalJwtRequest = (url, opts = {})=>{
    const rawJWT = localStorage.getItem("Account");
    const {jwt} = rawJWT?JSON.parse(rawJWT):undefined;
    return fetch(url, {...opts, headers: {...(opts.headers ?? {}), authorization: jwt}});
}
/** @typedef comment {{
 *     id: number,
 *     content: string,
 *     createdAt: string,
 *     modified: boolean=false,
 *     author: {
 *         username: string,
 *         avatar: boolean=false,
 *         profileViews: number=0
 *     }
 * }}
 */

/**
 * @param {string} hash
 * @return Promise<{
 *     imageViews: number=0,
 *     title: string,
 *     text: string='',
 *     createdAt: string,
 *     artist: {
 *         nickname: string=,
 *         website: string=
 *     }=,
 *     source: string=,
 *     uploader: {
 *         username: string,
 *         avatar: boolean=false,
 *         profileViews: number=0,
 *         color: string=,
 *         social: {
 *             twitter: string=
 *         }
 *     },
 *     searchTags: string[]=[],
 *     comments: comment[],
 *     likes: number=0,
 *     haveILiked: boolean=false
 * }>
 */
export const getImageMeta = async (hash)=>{
    const response = await optionalJwtRequest(`${API_URL}/i/${hash}`, {cache: 'reload'});
    return response.json();
}
/**
 * @return Promise<{
 *     data: {
 *         hash: string,
 *         createdAt: string,
 *         imageViews: number=0,
 *         comments: number=0,
 *         likes: number=0,
 *         nsfw: boolean=,
 *         title: string=undefined,
 *         uploader: {
 *             username: string
 *         }
 *     }[]=[],
 *     cursor: number
 * }>
 */
export const getGallery = async cursor=>{
    if (cursor === "0")
        return {cursor, data: []}
    const response = await fetch(`${API_URL}/gallery${cursor?'/'+cursor:''}`);
    return response.json();
}
/**
 * @param {string} hash
 * @return Promise<boolean>
 */
export const likeImage = async (hash)=>{
    const response = await jwtRequest(`${API_URL}/i/${hash}/like`, {method: 'PUT', cache: 'reload'});
    return response.ok;
}
/**
 * @param {string} username
 * @return Promise<{
 *     bio: string,
 *     avatar: boolean=false,
 *     profileViews: number=0,
 *     images: {
 *         hash: string,
 *         title: string,
 *         searchTags: string[]
 *     }[]=[],
 *     social: {
 *         twitter: string=
 *     },
 *     awards: {
 *         name: string,
 *         icon: string
 *     }[]
 *     albums: object[]=[],
 *     balance: number=0,
 *     amIFollowing: boolean=false
 * }>
 */
export const getUserProfile = async (username)=>{
    const response = await optionalJwtRequest(`${API_URL}/u/${username}`, {cache: 'reload'});
    return response.json();
}
/**
 * @param {{
 *     email: string=,
 *     bio: string=,
 *     avatar: string=,
 *     password: string=,
 *     follow: string=,
 *     unfollow: string=,
 *     social: {
 *         twitter: string=,
 *         reddit: string=
 *     }=
 * }} patch
 * @return {Promise<boolean>}
 */
export const patchUser = async patch=>{
    if (Object.keys(patch).length === 0)
        return true;

    const response = await jwtRequest(`${API_URL}/user`, {
        method: "PATCH",
        headers: {
            "content-type": "application/json"
        },
        body: JSON.stringify(patch)
    });
    return response.ok;
}
/**
 * @return Promise<{
 *     txs: object[],
 *     balance: number=0
 * }|boolean>
 */
export const getWalletInfo = async ()=>{
    const response = await jwtRequest(`${API_URL}/walletInfo`, {
        cache: 'reload',
        headers: {
            "content-type": "application/json"
        },
        //body: patch
    });
    return response.json();
}
/**
 * @param {{
 *     name: string,
 *     private: boolean=false
 * }} json
 * @return Promise<{id: number}|boolean>
 */
export const createAlbum = async json=>{
    const response = await jwtRequest(`${API_URL}/album`, {
        method: "POST",
        headers: {
            "content-type": "application/json"
        },
        body: JSON.stringify(json)
    });
    if (response.ok)
        return response.json();
    return false;
}
/**
 * @param {{
 *     name: string=,
 *     oldName: string,
 *     poster: string=
 * }} json
 * @return Promise<boolean>
 */
export const patchAlbum = async ({name, oldName, poster})=>{
    const response = await jwtRequest(`${API_URL}/a/${oldName}`, {
        method: "PATCH",
        headers: {
            "content-type": "application/json"
        },
        body: JSON.stringify({name, poster})
    });
    return response.ok;
}
/**
 * @param {string} name
 * @return Promise<boolean>
 */
export const deleteAlbum = async (name)=>{
    try{
        const response = await jwtRequest(`${API_URL}/a/${name}`, {
            method: "DELETE",
            headers: {
                "content-type": "application/json"
            }
        });
        return response.ok;
    } catch (e) {
        return false;
    }
}
/**
 * @param {number} id
 * @return Promise<{
 *     name: string,
 *     private: boolean,
 *     poster: string=,
 *     images: string[],
 *     owner: {
 *         username: string
 *     }
 * }|boolean>
 */
export const getAlbum = async id=>{
    try{
        const response = await optionalJwtRequest(`${API_URL}/a/${id}`, {
            cache: 'reload'
        });
        if (response.ok)
            return response.json();
        return false;
    } catch (e){
        return false;
    }
}
/**
 * @param {{
 *      album:number,
 *      cursor:string|number=0
 * }}json
 * @return Promise<{
 *      data: {
 *         hash: string,
 *         imageViews: number=0,
 *         comments: number=0,
 *         likes: number=0,
 *         title: string=undefined,
 *         uploader: {
 *             username: string
 *         }
 *     }[]=[],
 *     cursor: string=undefined
 *     }>
 */
export const getAlbumImages = async ({album, cursor= 0})=>{
    const jwt = JSON.parse(localStorage.getItem("Account")).jwt; //No cambiar, jwt es opcional
    const response = await fetch(`${API_URL}/a/${album}/${cursor}`, {
        headers: {
            authorization: jwt
        }
    });
    if (response.ok)
        return response.json();
    return false;
}
/**
 * @param {{
 *     name: string,
 *     image: string
 * }} json
 * @return Promise<boolean>
 */
export const addToAlbum = async ({image, name})=>{
    try {
        const response = await jwtRequest(`${API_URL}/i/${image}/album`, {
            method: "PUT",
            cache: 'reload',
            headers: {"content-type": "application/json"},
            body: JSON.stringify({name})
        });
        return response.ok;
    } catch (e) {
        return false;
    }
}
/**
 * @return Promise<{id: number, name: string, poster: string=""}[]>
 */
export const getMyAlbums = async ()=>{
    const {username} = JSON.parse(localStorage.getItem("Account"));
    const response = await jwtRequest(`${API_URL}/u/${username}/albums`, {cache: 'reload'});
    return response.json();
}

/**
 * @param {{
 *     image: string,
 *     content: string
 * }} json
 * @return Promise<{id: number}|boolean>
 */
export const postTopLevelComment = async ({image, content})=>{
    try {
        const response = await jwtRequest(`${API_URL}/i/${image}/comment`, {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({content})
        });
        return (await response.json()).id;
    } catch (e) {
        return false;
    }
}
/**
 * @param {{
 *     parent: number,
 *     content: string
 * }} json
 * @return Promise<{id: number}|boolean>
 */
export const postReplyComment = async json=>{
    try {
        const response = await jwtRequest(`${API_URL}/comment`, {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({content: json.content, parentId: json.parent})
        });
        return response.json();
    } catch (e) {
        return false;
    }
}
/**
 * @param {FormData} formData
 * @return {Promise<{hash: string}|boolean>}
 */
export const uploadPicture = async formData=>{
    try{
        const response = await jwtRequest(`${API_URL}/i/upload`, {
            method: "POST",
            body: formData
        });
        if (!response.ok){
            try {
                return await response.json();
            } catch (e){
                return false;
            }
        }
        return await response.json();
    } catch (e){
        return false;
    }
}
/**
 * @param {{
 *     hash: string,
 *     reason: string,
 *     additional: string=
 * }}json
 * @return Promise<any|boolean>
 */
export const reportImage = async ({hash, reason, additional})=>{
    try{
        const response = await jwtRequest(`${API_URL}/i/${hash}/report`, {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({reason, additional})
        });
        return response.ok;
    } catch(e){
        return false;
    }
}
/**
 * @param {{
 *     hash: string,
 *     patch: {
 *         source: string=,
 *         artist: string=,
 *         nsfw: boolean,
 *         tags: string=
 *     }
 * }} json
 * @return Promise<boolean>
 */
export const requestImageMetaUpdate = async json=>{
    try{
        const response = await jwtRequest(`${API_URL}/i/${json.hash}`, {
            method: "PATCH",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({patch: {...json.patch}})
        });
        return response.ok;
    } catch(e){
        return false;
    }
}
/**
 * @return Promise<{
 *     name: string,
 *     occurrences: number
 * }[]>
 */
export const getTags = async ()=>{
    try{
        const response = await fetch(`${API_URL}/tags`, {cache: "force-cache"});
        if (response.ok)
            return response.json();
        return [];
    } catch(e){
        return [];
    }
}
/**
 * @return Promise<{
 *      name: string,
 *      occurrences: number
 * }[]>
 */
export const getTopTags = async ()=>{
    try{
        const response = await fetch(`${API_URL}/topTags`, {cache: "force-cache"});
        if (response.ok)
            return response.json();
    } catch(e){}
    return [];

}
/**
 * @param {{
 *      tags: string[],
 *      cursor: string|number=0
 *      }} json
 * @return Promise<{
 *      data: {
 *          hash: string,
 *          createdAt: string,
 *          imageViews: number=0,
 *          comments: number=0,
 *          likes: number=0,
 *          title: string,
 *          uploader: {
 *              username: string
 *          }
 *     }[]=[],
 *     cursor: string|number=0}>
 */
export const search = async ({tags=[], cursor=0})=>{
    if (tags.length === 0 && !cursor)
        return await getGallery();
    try{
        const response = await fetch(`${API_URL}/search/${tags.join('/')}/${cursor}`, {cache: "force-cache"});
        if (response.ok)
            return response.json();
        return {data: [], cursor: 0};
    } catch(e){
        return {data: [], cursor: 0};
    }
}
/**
 * @param {number} id
 * @return Promise<{
 *     title: string,
 *     content: string=,
 *     createdAt: string,
 *     from: string
 * }>|boolean
 */
export const getMessage = async id=>{
    if (id === undefined)
        return false;
    try{
        const response = await jwtRequest(`${API_URL}/message/${id}`, {cache: "force-cache"});
        if (response.ok)
            return response.json();
        return false;
    } catch(e){
        return false;
    }
}
/**
 * @param {number[]} ids
 * @return Promise<boolean>
 */
export const deleteMessages = async ids=>{
    if (ids.length === 0)
        return true;
    try{
        const response = await jwtRequest(`${API_URL}/messages?data=${encodeURIComponent(JSON.stringify(ids))}`, {
            method: "DELETE",
            headers: {
                "content-type": "application/json"
            }
        });
        return response.ok;
    } catch(e){
        return false;
    }
}
/**
 * @param {{
 *     cursor: number,
 *     amount: number
 * }} json
 * @return Promise<{
 *     id: number,
 *     title: string,
 *     content: string,
 *     createdAt: string,
 *     from: string
 * }>[]=[]
 */
export const getMessages = async ({cursor, amount})=>{
    try{
        const response = await jwtRequest(`${API_URL}/messages/${cursor}/${amount}`, {cache: "no-store"});
        return response.json();
    } catch(e){
        return [];
    }
}
/**
 * @return Promise<{
 *     id: number,
 *     title: string,
 *     content: string,
 *     createdAt: string,
 *     from: string
 * }>[]=[]
 */
export const getInbox = async ()=>{
    try{
        const response = await jwtRequest(`${API_URL}/inbox`, {cache: "no-store"});
        return response.json();
    } catch(e){
        return [];
    }
}
/**
 * @return Promise<{
 *     id: number,
 *     title: string,
 *     content: string,
 *     createdAt: string,
 *     to: string
 * }>[]=[]
 */
export const getOutbox = async ()=>{
    try{
        const response = await jwtRequest(`${API_URL}/outbox`, {cache: "no-store"});
        const json = await response.json();
        const {username} = JSON.parse(localStorage.getItem("Account"));
        return json.map(x=>({...x, from: username}));
    } catch(e){
        return [];
    }
}
/**
 * @param {{
 *     title: string=
 *     content: string=,
 *     to: string=,
 *     replyTo: string=
 * }}json
 * @return Promise<boolean>
 */
export const sendMessage = async json=>{
    try{
        const response = await jwtRequest(`${API_URL}/message`, {
            method: "POST",
            headers: {"content-type": "application/json"},
            body: JSON.stringify(json)
        });
        return response.ok;
    } catch(e){
        return false;
    }
}
/**
 * @param {{
 *     username: string,
 *     email: string,
 *     password: string
 * }}json
 * @return Promise<{jwt: string}> | boolean
 */
export const register = async json=>{
    try{
        const response = await fetch(`${API_URL}/signup`, {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(json)
        });
        if (response.ok)
            return response.json();
        return false;
    } catch(e){
        return false;
    }
}
/**
 * @param {{
 *     email: string,
 *     password: string
 * }}json
 * @return Promise<{
 *     jwt: string,
 *     username: string
 * }|boolean>
 */
export const login = async ({email, password})=>{
    try{
        const response = await fetch(`${API_URL}/login`, {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({email, password})
        });
        if (response.ok)
            return response.json();
        return false;
    } catch(e){
        return false;
    }
}
/**
 * @param {string} username
 * @return Promise<boolean>
 */
export const isUsernameAvailable = async username=>{
    try{
        const response = await fetch(`${API_URL}/u/${username}`, {
            method: "HEAD",
            cache: "force-cache",
        });
        return response.ok;
    } catch(e){
        return false;
    }
}
/**
 * @param {{
 *     cursor: string|number=0
 * }} json
 * @return Promise<{
 *      data: {
 *          hash: string,
 *          createdAt: string,
 *          imageViews: number=0,
 *          comments: number=0,
 *          likes: number=0,
 *          title: string,
 *          uploader: {
 *              username: string
 *          }
 *     }[]=[],
 *     cursor: string=0}>
 */
export const getFeed = async ({cursor= 0})=>{
    try{
        const response = await jwtRequest(`${API_URL}/feed/${cursor}`);
        return response.json();
    } catch(e){
        return {data: [], cursor: "0"};
    }
}
/**
 * @param {{
 *     parent: number,
 *     cursor: number=0
 * }} json
 * @return Promise<{
 * data: {
 *     id: number,
 *     content: string,
 *     createdAt: string,
 *     modified: boolean=false,
 *     author: {
 *         username: string,
 *         color: string=,
 *         profileViews: number
 *     }
 * }[]=[],
 * cursor: string=0}>
 */
export const getChildComments = async ({parent, cursor=0})=>{
    try{
        const response = await fetch(`${API_URL}/comment/${parent}/${cursor}`, {cache: "force-cache"});
        return response.json();
    } catch(e){
        return false;
    }
}
/**
 * @param {{
 *     hash: string,
 *     cursor: string=''
 * }}json
 * @return Promise<{{
 *     username: string,
 *     color: string=null
 * }}[]>
 */
export const getImageLikes = async ({hash, cursor=''})=>{
    try{
        const response = await fetch(`${API_URL}/i/${hash}/likes${cursor}`, {cache: "force-cache"});
        return response.json();
    } catch(e){
        return [];
    }
}

/**
 * @param {number} request
 * @param {string} content
 * @return Promise<boolean>
 */
export const sendFeedback = async ({request, content})=>{
    try{
        const response = await jwtRequest(`${API_URL}/feedback/${request}`, {
            method: 'POST',
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify({content})
        });
        return response.ok;
    } catch(e){
        return false;
    }
}
/**
 * @return Promise<{{
 *     id: number,
 *     title: string,
 *     description: string=
 * }}|boolean>
 */
export const getFeedbackRequests = async ()=>{
    try{
        const response = await jwtRequest(`${API_URL}/feedback`);
        return response.json();
    } catch(e){
        return false;
    }
}
/**
 * @return Promise<{
 *     name: string,
 *     progress: number,
 *     cost: number
 * }[]>
 */
export const getDonationInfo = async ()=>{
    try{
        const response = await fetch(`${API_URL}/donations`);
        return response.json();
    } catch(e){
        return false;
    }
}
/**
 * @param {string} tag
 * @return Promise<{
 *     image: string,
 *     title: string=,
 *     description: string,
 *     images: {
 *         hash: string,
 *         imageViews: number,
 *         nsfw: boolean,
 *         uploader: {
 *             username: string,
 *             color: string
 *         },
 *         comments: number,
 *         likes: number
 *     }
 * }|boolean>
 */
export const getTagInfo = async tag=>{
    try {
        const response = await fetch(`${API_URL}/tag/${tag}/info`);
        return response.json();
    } catch (e){
        return false;
    }
}