<svelte:options immutable={true}/>
<script>
    import {route} from '@bjornlu/svelte-router';
    import {Head, schemas} from "../stores.mjs";

    let oldTags = [];
    route.subscribe(()=>Head.reset());  //Reset head on navigation
</script>
<svelte:head>
    <title>{$Head.title ?? schemas.head.title}</title>
    <link rel="canonical" href="{$Head.canonical ?? window.location.href}" bind:this={oldTags[0]}>
    <link rel="alternate" hreflang="{$Head?.alternate?.hreflang ?? schemas.head.alternate.hreflang}" href="{$Head?.alternate?.url ?? schemas.head.alternate.url}" bind:this={oldTags[1]}>
    <link rel="shorcut icon" type="{$Head?.icon?.mimetype ?? schemas.head.icon.mimetype}" sizes="{$Head?.icon?.size ?? schemas.head.icon.size}x{$Head?.icon?.size ?? schemas.head.icon.size}" href="{$Head?.icon?.url ?? schemas.head.icon.url}" bind:this={oldTags[2]}>

    {#each [...$Head.meta, ...schemas.head.meta.filter(x=>!$Head.meta.map(x=>x.name).includes(x.name))] as tag, i}
        <meta name="{tag.name}" content="{tag.content}" bind:this={oldTags[i+3]}>
    {/each}
    {#each [...($Head.ld ?? [])] as ld, i}
        {@html `<script type="application/ld+json">${JSON.stringify(ld)}</script>`}
    {/each}
</svelte:head>