<svelte:options immutable={true}/>
<script>
    import {MATCH_ANYTHING_IN_PARENTHESES, MATCH_UP_TO_FIRST_PARENTHESE} from '../../utils/regex.mjs';
    import {THUMBNAIL_ROUTE_PREFIX} from '../../constants.js';
    import {route} from '@bjornlu/svelte-router';
    import {Head} from '../../stores.mjs';
    import {fade} from 'svelte/transition';

    /** @type String */
    export let hash;
    /** @type String */
    export let description;

    const character = {
        name: decodeURIComponent($route.params.tag).match(MATCH_UP_TO_FIRST_PARENTHESE)[0].trimRight(),
        show: decodeURIComponent($route.params.tag).match(MATCH_ANYTHING_IN_PARENTHESES)[0]
    };

    Head.update(x=>({
        ...x,
        title: `${character.name} from ${character.show}`,
        meta: [...(x.meta ?? []), {name: 'description', content: description}],
        ld: [...x.ld, {
            '@context': 'https://schema.org',
            '@type': 'Article',
            'name': character.name,
            'url': window.location.href,
            'author': {
                '@type': 'Organization',
                'name': 'Waifu page'
            },
            'image': `${THUMBNAIL_ROUTE_PREFIX}/${hash}_512x512.webp`,
            'headline': 'Anime character'
        }]
    }));
</script>
<style>
    h2 {
        font-size: 0.9rem;
    }
    .card {
        white-space: pre-line;
        display: grid;
        grid-template-columns: 0.6666fr 0.3333fr;
        min-height: 256px;
    }
    main {
        grid-area: 1 / 1 / 2 / 4;
    }
    a {
        grid-area: 1 / 4 / 2 / 5;
        min-width: 256px;
    }
</style>

<div class="card box-shadow bg-dark" in:fade>
    <main class="card-body">
        <h1 class="mb-0 font-weight-bold">
            {character.name}
        </h1>
        <h2 class="mb-1 text-muted">Appears in {character.show}</h2>
        <p class="card-text mb-auto">{description ?? 'There is no user-generated description for this character yet'}</p>
    </main>

    <a href="/i/{hash}" aria-label="view original post" class="m-2 d-none d-sm-flex aspect-ratio-1">
        <picture in:fade>
            <source type="image/webp" media="(min-width: 300px)" srcset={`${THUMBNAIL_ROUTE_PREFIX}/${hash}_256x256.webp`}/>
            <source type="image/webp" media="(min-width: 1920px)" srcset={`${THUMBNAIL_ROUTE_PREFIX}/${hash}_512x512.webp`}/>
            <img width="128" height="128" class="card-img-right align-self-center" crossorigin="anonymous" alt="{character.name}'s photo" src={`${THUMBNAIL_ROUTE_PREFIX}/${hash}_128x128.webp`}>
        </picture>
    </a>
</div>
