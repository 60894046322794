<svelte:options immutable={false}/>
<script>
    import {createEventDispatcher} from 'svelte';
    import {fade, slide} from 'svelte/transition';

    const dispatch = createEventDispatcher();
    /** @type {string} */
    let tag = '';
    let arrelementsmatch = [];
    const regExpEscape = s=>s.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');

    /** @type {string[]} */
    export let tags;
    /** @type {number[]} */
    export let addKeys;
    /** @type {number} */
    export let maxTags;
    /** @type {boolean} */
    export let onlyUnique;
    /** @type {number[]} */
    export let removeKeys;
    /** @type {string} */
    export let placeholder;
    /** @type {boolean} */
    export let allowPaste;
    /** @type {boolean} */
    export let allowDrop;
    export let splitWith;
    /** @type {boolean} */
    export let autoComplete;
    /** @type {string} */
    export let name;
    /** @type {string} */
    export let id;
    /** @type {boolean} */
    export let allowBlur;
    /** @type {boolean} */
    export let disable;
    /** @type {number} */
    export let minChars;
    /** @type {string} */
    export let classess = '';
    /** @type {string} */
    export let style = '';
    /** @type {boolean} */
    export let col;

    $: tags = tags || [];
    $: addKeys = addKeys || [13];
    $: maxTags = maxTags || false;
    $: onlyUnique = onlyUnique || false;
    $: removeKeys = removeKeys || [8];
    $: placeholder = placeholder || '';
    $: allowPaste = allowPaste || false;
    $: allowDrop = allowDrop || false;
    $: splitWith = splitWith || ',';
    $: autoComplete = autoComplete || false;
    $: name = name || 'svelte-searchTags-input';
    $: id = id || 'sti_' + Math.random().toString(36).substr(2, 9);
    $: allowBlur = allowBlur || false;
    $: disable = disable || false;
    $: minChars = minChars || 1;

    $: matchsID = id + '_matchs';

    let storePlaceholder = placeholder;

    /** @type {boolean} */
    let isDisabled = false;

    function setTag(input){
        const currentTag = input.target.value;

        if (addKeys){
            addKeys.forEach(function (key){
                if (key === input.keyCode){

                    if (currentTag)
                        input.preventDefault();

                    switch (input.keyCode){
                        case 9:
                            // TAB add first element on the autoComplete list
                            if (autoComplete && document.getElementById(matchsID))
                                addTag(document.getElementById(matchsID).querySelectorAll('li')[0].textContent);
                            else
                                addTag(currentTag);
                            break;
                        default:
                            addTag(currentTag);
                            break;
                    }
                }
            });
        }

        if (removeKeys){
            removeKeys.forEach(function (key){
                if (key === input.keyCode && tag === ''){
                    tags.pop();
                    tags = tags;

                    dispatch('tags', {
                        tags: tags
                    });

                    arrelementsmatch = [];
                    isDisabled = false;
                    placeholder = storePlaceholder;
                    document.getElementById(id).focus();
                }
            });
        }

        // ArrowDown : focus on first element of the autocomplete
        if (input.keyCode === 40 && autoComplete && document.getElementById(matchsID)){
            event.preventDefault();
            document.getElementById(matchsID).querySelector('li:first-child').focus();
        } // ArrowUp : focus on last element of the autocomplete
        else if (input.keyCode === 38 && autoComplete && document.getElementById(matchsID)){
            event.preventDefault();
            document.getElementById(matchsID).querySelector('li:last-child').focus();
        }

    }

    function addTag(currentTag){

        currentTag = currentTag.trim();

        if (currentTag == '')
            return;
        if (maxTags && tags.length == maxTags)
            return;
        if (onlyUnique && tags.includes(currentTag))
            return;

        tags.push(currentTag);
        tags = tags;
        tag = '';

        dispatch('tags', {
            tags: tags
        });

        // Hide autocomplete list
        // Focus on svelte searchTags input
        arrelementsmatch = [];
        document.getElementById(id).focus();

        if (maxTags && tags.length == maxTags){
            isDisabled = true;
            placeholder = '';
        }

    }

    function removeTag(i){
        tags.splice(i, 1);
        tags = tags;

        dispatch('tags', {
            tags: tags
        });

        // Hide autocomplete list
        // Focus on svelte searchTags input
        arrelementsmatch = [];
        isDisabled = false;
        placeholder = storePlaceholder;
        document.getElementById(id).focus();
    }

    function onPaste(e){
        if (!allowPaste)
            return;

        e.preventDefault();

        const data = getClipboardData(e);
        const tags = splitTags(data).map(tag=>addTag(tag));
    }

    function onDrop(e){
        if (!allowDrop)
            return;

        e.preventDefault();

        const data = e.dataTransfer.getData('Text');
        const tags = splitTags(data).map(tag=>addTag(tag));
    }

    function onBlur(tag){
        if (!document.getElementById(matchsID) && allowBlur){
            event.preventDefault();
            addTag(tag);
        }

    }

    function getClipboardData(e){
        if (window.clipboardData)
            return window.clipboardData.getData('Text');
        if (e.clipboardData)
            return e.clipboardData.getData('text/plain');
        return '';
    }

    const splitTags = data=>data.split(splitWith).map(tag=>tag.trim());

    function getMatchElements(input){
        if (!autoComplete)
            return;

        const value = input.target.value;

        // Escape
        if (value == '' || input.keyCode === 27 || value.length < minChars){
            arrelementsmatch = [];
            return;
        }

        let matchs = autoComplete.filter(e=>e.toLowerCase().includes(value.toLowerCase())).map(matchTag=>({
            label: matchTag,
            search: matchTag.replace(RegExp(regExpEscape(value.toLowerCase()), 'i'), '<strong>$&</strong>')
        }));

        if (onlyUnique === true)
            matchs = matchs.filter(tag=>!tags.includes(tag.label));

        arrelementsmatch = matchs;
    }

    function navigateAutoComplete(autoCompleteIndex, autoCompleteLength, autoCompleteElement){
        if (!autoComplete)
            return;

        event.preventDefault();

        // ArrowDown
        if (event.keyCode === 40){
            // Last element on the list ? Go to the first
            if (autoCompleteIndex + 1 === autoCompleteLength){
                document.getElementById(matchsID).querySelector('li:first-child').focus();
                return;
            }
            document.getElementById(matchsID).querySelectorAll('li')[autoCompleteIndex + 1].focus();
        } else if (event.keyCode === 38){
            // ArrowUp
            // First element on the list ? Go to the last
            if (autoCompleteIndex === 0){
                document.getElementById(matchsID).querySelector('li:last-child').focus();
                return;
            }
            document.getElementById(matchsID).querySelectorAll('li')[autoCompleteIndex - 1].focus();
        } else if (event.keyCode === 13){
            // Enter
            addTag(autoCompleteElement);
        } else if (event.keyCode === 27){
            // Escape
            arrelementsmatch = [];
            document.getElementById(id).focus();
        }
    }
</script>

<div class="position-relative {classess}" class:svelte-tags-input-layout-row={!col} class:svelte-tags-input-layout-col={col} style="{style}" class:sti-layout-disable={disable}>
    {#if tags.length > 0}
        {#each tags as tag, i}
            <span out:fade={{duration: 200}} class="svelte-tags-input-tag my-auto">{tag}
                {#if !disable}
                    <span class="svelte-tags-input-tag-remove" on:click={() => removeTag(i)}> &#215;</span>
                {/if}
            </span>
        {/each}
    {/if}
    <input type="text" class="svelte-tags-input" placeholder={tags?.length>0?'':placeholder} id={id} name={name}
           bind:value={tag} on:keydown={setTag} on:keyup={getMatchElements} on:paste={onPaste} on:drop={onDrop}
           on:blur={() => onBlur(tag)} autocomplete=off readonly={isDisabled} class:d-none={isDisabled}
           disabled={disable}>
    {#if autoComplete && arrelementsmatch.length > 0}
        <div class="svelte-tags-input-matchs-parent position-absolute w-100">
            <ul transition:slide id="{id}_matchs" class="svelte-tags-input-matchs">
                {#each arrelementsmatch as element, index}
                    <li tabindex="-1"
                        on:keydown={() => navigateAutoComplete(index, arrelementsmatch.length, element.label)}
                        on:click={() => addTag(element.label)}>{@html element.search}</li>
                {/each}
            </ul>
        </div>
    {/if}
</div>

<style>
    /* svelte-searchTags-input css */
    input {
        outline: 0;
        border: unset !important;
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem !important;
        font-size: 1.167rem;
        font-weight: bold;
        line-height: 1.5;
        border-radius: 0.25rem;
    }

    input:read-only {
        cursor: not-allowed;
    }

    .svelte-tags-input,
    .svelte-tags-input-tag,
    .svelte-tags-input-matchs {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    }

    .svelte-tags-input-matchs::-webkit-scrollbar {
        height: 12px;
        width: 6px;
        background: #333;
    }

    .svelte-tags-input-matchs::-webkit-scrollbar-thumb {
        background: #1a1a1a;
    }

    /* svelte-searchTags-input-layout */

    .svelte-tags-input-layout-col {
        display: flex;
        flex-direction: column;
        background: #444444;
        border-radius: 4px;
    }
    .svelte-tags-input-layout-row{
        display: flex;
        flex-direction: row;
        background: #444444;
        border-radius: 4px;
    }

    /* svelte-searchTags-input */

    .svelte-tags-input {
        flex: 1;
        background-color: white;
        padding: 0 0.3em;
    }

    /* svelte-searchTags-input-tag */

    .svelte-tags-input-tag {
        width: min-content;
        height: min-content;
        white-space: nowrap;
        list-style: none;
        background: #1a1a1a;
        color: #1cc88a;
        padding: 0.1em 0.4em;
        border-radius: 0.2em;
        margin-right: 0.1em;
        margin-left: 0.1em;
        margin-top: 0.2em;
    }

    .svelte-tags-input-tag:hover {
        opacity: 0.75;
    }

    .svelte-tags-input-tag-remove {
        cursor: pointer;
    }

    /* svelte-searchTags-input-matchs */

    .svelte-tags-input-matchs-parent {
        bottom: 0;
        position: relative;
    }

    .svelte-tags-input-matchs {
        z-index: 999;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 3px 0;
        padding: 0;
        background: #444444;
        border-radius: 2px;
        max-height: 310px;
        overflow: scroll;
        overflow-x: auto;
    }

    .svelte-tags-input-matchs li {
        list-style: none;
        padding: 4px;
        border-top: 1px solid #1a1a1a;
        border-radius: 0 0 2px 2px;
        cursor: pointer;
    }
    .svelte-tags-input-matchs li:last-child {
        border-bottom: none;
    }
    .svelte-tags-input-matchs li:first-child {
        border-top: none;
    }

    .svelte-tags-input-matchs li:hover,
    .svelte-tags-input-matchs li:focus {
        background: #1cc88a;
        color: #1a1a1a;
    }
</style>