import {CONTAINS_PARENTHESES_PAIRS} from './regex.mjs';

/**
 * @param {string[]} tags
 * @return {{
 *      characters: string[],
 *      others: string[]
 * }}
 */
export default tags=>{
    const characters = tags.filter(a=>CONTAINS_PARENTHESES_PAIRS.test(a));
    const others = tags.filter(a=>!characters.includes(a));
    return {characters, others};
}