<svelte:options immutable={true}/>
<script>
    import {onMount} from 'svelte';
    import {FAVICON_MIME, FAVICON_URL} from './constants.js';
    import { iobserve } from '@shibiii/svelte-iobserve';
    import {Gallery, Head} from './stores.mjs';

    import ImageThumb from './fragments/ImageThumb.svelte';
    import {fade} from "svelte/transition";

    /** @type {boolean} */
    let isAlreadyLoading;
    /** @type {HTMLElement} */
    let scroller;

    /** @type {Number} */
    let renderedIndex = 0;

    /**
     * @param {number} [n=6]
     */
    const updateIndex = (n=6)=>{
        if (n<0)
            return;
        renderedIndex = Math.min(renderedIndex+n, $Gallery.length);
        if (renderedIndex < $Gallery.length || renderedIndex === 0)
            requestAnimationFrame(updateIndex);
    }

    onMount(()=>updateIndex(32));

    /** @param {number} i */
    const calculateDelay = i=>Math.min(150+50*i, 550);
    /**
     * @return Promise<void>
     */
    const loadMore = async () => {
        if (isAlreadyLoading)
            return;
        isAlreadyLoading = true;
        await Gallery.fetchMore();
        updateIndex(Math.min(24, $Gallery.length-24));
        isAlreadyLoading = false;
    }
    $Head = {
        title: "Gallery",
        meta: [
            {name: "description",               content: "Upload, view, save and share the images you like"},
            {name: "rating",                    content: "adult"},
            {name: "twitter:card",              content: "summary"},
            {name: "twitter:title",             content: "Waifu gallery"},
            {name: "twitter:image",             content: FAVICON_URL},
            {name: "og:type",                   content: "website"},
            {name: "og:title",                  content: "Waifu gallery"},
            {name: "og:image",                  content: FAVICON_URL},
            {name: "og:image:type",             content: FAVICON_MIME},
            {name: "og:locales",                content: "en_EN"},
        ]
    }
</script>


{#if $Gallery?.length===0}
    <div class="spinner-border spinner-big m-auto d-block"></div>
{:else}
    <div bind:this={scroller} class="img-grid">
        {#each {length: renderedIndex} as _, i}
            {#if i===renderedIndex-12}
                <div let:this={tagged} on:intersection={loadMore} use:iobserve={{ once: true, options: {root: scroller, element: tagged, rootMargin: '450px'} }} in:fade={{delay: calculateDelay(i), duration: 250}}>
                    <ImageThumb image={$Gallery[i]}/>
                </div>
            {:else}
                <div in:fade={{delay: calculateDelay(i), duration: 250}}>
                    <ImageThumb image={$Gallery[i]}/>
                </div>
            {/if}
        {/each}
    </div>
{/if}
