<svelte:options immutable={true}/>
<script>
    import { fade, fly } from "svelte/transition";
    import { quintOut } from "svelte/easing";

    export let open = false;

    const modalClose = ()=>open = false;
</script>

<style>
    .modal {
        display: block;
        background-color: rgba(0,0,0,0.25);
        overflow: auto;
        border-width: 0;
    }
    .modal-dialog {
        max-height: -webkit-fill-available;
    }
</style>

{#if open}
    <dialog class="modal show" tabindex="-1" on:click|self|stopPropagation={modalClose} transition:fade>
        <div class="modal-dialog" role="document" in:fly={{ y: -50, duration: 400 }} out:fly={{ y: -50, duration: 400, easing: quintOut }}>
            <div class="modal-content">
                <slot close={modalClose} />
            </div>
        </div>
    </dialog>
    <div class="modal-backdrop show" on:click={modalClose} transition:fade={{ duration: 250 }} />
{/if}
