<svelte:options immutable={true}/>
<script>
    import {Link} from '@bjornlu/svelte-router';
    import Modal from '../fragments/Modal.svelte';
    import ErrorModal from '../modals/ErrorModal.svelte';
    import {Account} from '../stores.mjs';
    import Comment from './Comment.svelte';
    import {AVATAR_STORAGE_DIR, USER_COLORS, AVATAR_MINI_SIZE} from '../constants.js';
    import {iobserve} from "@shibiii/svelte-iobserve";
    import {getChildComments, postReplyComment} from '../utils/API.mjs';
    import clickoutside from '../utils/clickoutside.mjs';
    import {slide} from "svelte/transition";

    /** @type {comment} */
    export let comment;

    /** @type {number} */
    let cursor;
    /** @type {comment[]} */
    let childComments;
    /** @type {string} */
    let response;
    /** @type {boolean} */
    let showError;
    /** @type {HTMLElement} */
    let scroller;
    /** @type {boolean} */
    let showChildren;
    /** @type {{hideChildren: function(void) void}[]} Recursive array of Comment svelte components*/
    let childElements = [];
    /** @type {boolean} */
    $: hasMore = cursor && cursor !== 0;

    const loadMore = async ()=>{
        const result = await getChildComments({parent: comment.id, cursor});
        cursor = result.cursor;
        childComments = result.data;
    };
    const handleClick = ()=>{
        showChildren = !showChildren;
        if (cursor===undefined)
            loadMore();
    }
    const calculateSlideDelay = i=>(i-1)*75;
    export const hideChildren = ()=>{
        showChildren = false;
        childElements.forEach(e=>e.hideChildren());
    }
    const handleReply = async ()=>{
        const res = await postReplyComment({parent: comment.id, content: response});
        if (res.id)
            childComments = [...childComments, {
                id: res.id,
                content: response,
                createdAt: (new Date()).toISOString(),
                modified: false,
                author: {username: $Account.username, profileViews: 0}
            }];
        else
            showError = true;
    };
</script>

<style>
    .cardbox-comments{
        padding: 2rem 40px 20px 40px;
        text-align: center;
        width: 100%;
        background-color: #222;
    }
    .cardbox-comments .comment-avatar img{
        margin-top: 1px;
        margin-right: 10px;
        position: relative;
        display: inline-block;
        text-align: center;
        width: 40px;
        height: 40px;
    }
    .cardbox-comments p {
        color: white;
    }
    .active>p{
        color: var(--orange);
    }
</style>

<Modal bind:open={showError}>
    <ErrorModal description={"There was an error saving your comment"} fix={"Try again"}/>
</Modal>
<div use:clickoutside={{cond: ()=>showChildren}} on:clickOutside={()=>{showChildren = false; hideChildren()}}>
    <div class="cardbox-comments row w-100 pointer" class:bg-dark={showChildren} class:active={showChildren} on:click={handleClick} bind:this={scroller}>
        <span class="comment-avatar">
            <Link to="/u/{comment.author.username}">
                <img crossorigin="anonymous" src="{AVATAR_STORAGE_DIR}/{comment.author.username}_mini.webp" loading="lazy" class="rounded-circle mr-0" height="{AVATAR_MINI_SIZE}" width="{AVATAR_MINI_SIZE}" alt="{comment.author.username}'s avatar">
                <p class="font-weight-bold {USER_COLORS[comment.author.color] ?? ''}">{comment.author.username}</p>
            </Link>
        </span>
        <p class="overflow-auto ml-4 text-justify" class:font-italic={comment.modified}>{comment.content}</p>

        {#if childComments?.length > 0 && showChildren}
            <div class="col col-12" on:click|stopPropagation>
                {#each childComments as comment, i (comment.id)}
                    {#if i === childComments.length-2 && hasMore}
                        <div let:this={tagged} on:intersection={loadMore} use:iobserve={{ once: true, options: {root: scroller, element: tagged, rootMargin: '450px'} }} transition:slide={{delay: calculateSlideDelay(i)}}>
                            <Comment {comment} bind:this={childElements[i]}/>
                        </div>
                    {:else}
                        <div transition:slide={{delay: calculateSlideDelay(i)}}>
                            <Comment {comment} bind:this={childElements[i]}/>
                        </div>
                    {/if}
                {/each}
            </div>
        {/if}
    </div>
    {#if cursor===0 && showChildren && childComments?.length === 0}
        <p class="w-100 text-center text-muted px-5">This comment has no children</p>
    {/if}

    {#if cursor!==undefined && showChildren && $Account.username}
        <form class="form-inline p-5" on:submit|preventDefault={handleReply} style="gap: 1rem">
            <label class="sr-only" for="comment">Comment</label>
            <textarea bind:value={response} id="comment" placeholder="Reply here"></textarea>
            <button class="btn btn-success btn-block" type="submit" style="color: #1a1a1a">Reply</button>
        </form>
    {/if}
</div>
