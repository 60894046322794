<svelte:options immutable={false}/>
<script>
    import {CONTAINS_PARENTHESES_PAIRS} from '../../utils/regex.mjs';
    import {Link} from '@bjornlu/svelte-router';
    import {TopTags} from "../../stores.mjs";

    import Fa from 'svelte-fa';
    import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';

    export let imageTags;

    let items = [];
    const kickstart = (i = 0)=>{
        if (items[i]){
            items[i].classList.add('slideDown');
            if (i < items.length - 1)
                setTimeout(()=>requestAnimationFrame(()=>kickstart(i + 1)), 75)
        }
    }
    $: {
        if (items.length > 0)
            kickstart();
    }
</script>

<style>
    .donation-header {
        font-size: 1.2rem;
        font-weight: bold;
        color: var(--orange);
    }
    li {
        content-visibility: hidden;
    }
</style>
{#if !imageTags} <!--Tags are hot tags not image tags-->
    <header class="donation-header mt-5 d-none d-md-block">Hot tags</header>
{:else} <!--We are in an ImagePost-->
    <header class="donation-header mt-5 d-none d-md-block">Image tags</header>
{/if}
<ol class="list-unstyled text-capitalize">
    {#each (imageTags ?? $TopTags).sort((a, b)=>{
        const aHasParentheses = CONTAINS_PARENTHESES_PAIRS.test(a.name ?? a);
        const bHasParentheses = CONTAINS_PARENTHESES_PAIRS.test(b.name ?? b);
        if (aHasParentheses)
            return -1;
        if (bHasParentheses)
            return 0;
        if ((aHasParentheses && bHasParentheses) || (!aHasParentheses && !bHasParentheses))
            return a.occurrences > b.occurrences ? -1 : 1;
        return -1;
    }) as tag, i}
        <li bind:this={items[i]}>
            {#if tag.occurrences}
                <Link to="/search/{tag.name}/0" rel="tag" class="{CONTAINS_PARENTHESES_PAIRS.test(tag.name)?'character':''}">
                    {tag.name} ({tag.occurrences})
                </Link>
            {:else}
                <Link to="/search/{tag}/0" rel="tag" class="{CONTAINS_PARENTHESES_PAIRS.test(tag)?'character':''}">
                    {tag}
                </Link>
            {/if}
            {#if CONTAINS_PARENTHESES_PAIRS.test(tag.name ?? tag)}
                <Link to="/tag/{(tag.name ?? tag)}" aria-label="info">
                    <Fa icon={faInfoCircle} class="text-muted position-absolute ml-1"/>
                </Link>
            {/if}
        </li>
        {#if i === (imageTags ?? $TopTags).length - 1}
            <li bind:this={items[i+1]} class="mt-2">
                <Link to="/tags">All tags...</Link>
            </li>
        {/if}
    {/each}
</ol>