<svelte:options immutable={true}/>
<script>
    import {Link, route} from '@bjornlu/svelte-router';
    import {getAlbum, getAlbumImages} from '../utils/API';
    import {Account, Head} from '../stores.mjs';
    import {THUMBNAIL_ROUTE_PREFIX, AVATAR_STORAGE_DIR, AVATAR_MINI_SIZE} from '../constants.js';
    import {iobserve} from '@shibiii/svelte-iobserve';
    import {linear} from '../utils/math/sequence.mjs';

    import Modal from '../fragments/Modal.svelte';
    import ImageThumb from '../fragments/ImageThumb.svelte';
    import PatchAlbumModal from '../modals/PatchAlbumModal.svelte';
    import Fa from 'svelte-fa';
    import {faEdit} from '@fortawesome/free-solid-svg-icons';
    import {fade} from 'svelte/transition';


    let loadedImages = [];
    let cursor = undefined;
    let isLoading = true;
    $: hasMore = cursor !== '0';
    let album;
    let isModalOpen;
    let scroller;

    const pload = getAlbum(parseInt($route.params.id)).then(async x=>{
        album = x;
        isLoading = true;
        const response = await getAlbumImages({album: parseInt($route.params.id), cursor: undefined});
        loadedImages = [...loadedImages, ...response.data];
        cursor = response.cursor;
        isLoading = false;
        let posterURL = `${THUMBNAIL_ROUTE_PREFIX}/${(album.poster ?? album.images?.[0])}`;
        if (!posterURL)
            posterURL = `${THUMBNAIL_ROUTE_PREFIX}/album_placeholder.webp`;
        else
            posterURL += 'posterBaseURL';
        Head.set({
            canonical: window.location.href,
            title: `${album.name} album`,
            meta: [
                {name: 'robots', content: x?.images?.length > 0 ? 'follow,archive,snippet' : 'noindex,follow'},
                {
                    name: 'description',
                    content: `${album.name ?? 'Nonexistent'} album by ${album?.owner?.username ?? 'Nonexistent user'}`
                },
                {name: 'author', content: album?.owner?.username},
                {name: 'rating', content: 'general'},
                {name: 'twitter:card', content: 'summary_large_image'},
                {
                    name: 'twitter:title',
                    content: `${album.name ?? 'Nonexistent'} album by ${album?.owner?.username ?? 'Nonexistent user'}`
                },
                {name: 'twitter:image', content: posterURL},
                {
                    name: 'og:title',
                    content: `${album.name ?? 'Nonexistent'} album by ${album?.owner?.username ?? 'Nonexistent user'}`
                },
                {name: 'og:image', content: posterURL},
                {name: 'og:image:type', content: 'image/webp'},
                {name: 'og:image:width', content: '512'},
                {name: 'og:image:height', content: '512'},
                {name: 'og:image:url', content: window.location.href}
            ]
        });
        return x;
    });
    const loadMore = async ()=>{
        if (isLoading)
            return;
        isLoading = true;
        const result = await getAlbumImages({album: parseInt($route.params.id), cursor});
        if (result && result?.data?.length > 0)
            loadedImages = [...loadedImages, ...result.data];
        cursor = result.cursor ?? '0';
        isLoading = false;
    };

    const calculateDelay = linear({step: 50, initial: 250, limit: 1000});
</script>
<style>
    h1{
        font-family: "Helvetica Neue",serif;
        font-weight: bold;
    }
    h2 {
        color: var(--gray);
        font-size: 1.2rem;
        font-weight: bolder;
    }
</style>
<Modal bind:open={isModalOpen} let:close={close}>
    <PatchAlbumModal form={album} {close}/>
</Modal>

<div bind:this={scroller}>
    {#await pload then album}
        {#if !album}
            <p class="mx-auto text-muted mt-5">This album does not exist</p>
        {:else}
            <section class="d-flex flex-column justify-content-center mb-5">
                {#if album.owner.username === $Account.username}
                    <div class="text-center w-100 mt-5 mx-auto d-flex justify-content-center">
                        <h1>{album.name}</h1>
                        <i on:click={()=>isModalOpen=true}>
                            <Fa icon={faEdit} fw class="align-self-start pointer"/>
                        </i>
                    </div>
                {:else}
                    <h1 class="text-center m-auto mt-5">{album.name}</h1>
                {/if}
                <Link class="mx-auto mt-3" to="/u/${album.owner.username}" rel="author">
                    <img crossorigin="anonymous" class="img-fluid rounded-circle" src="{AVATAR_STORAGE_DIR}/{album.owner.username}_mini.webp" height="{AVATAR_MINI_SIZE}" width="{AVATAR_MINI_SIZE}" alt="{`${album.owner.username.username}'s avatar`}">
                </Link>
                <Link to="/u/{album.owner.username}" class="w-100 mt-2" rel="author">
                    <h2 class="text-center p-2">{album.owner.username}</h2>
                </Link>
            </section>
            {#if loadedImages.length === 0}
                <p class="mx-auto text-muted mt-5">This album is empty</p>
            {:else}
                <div class="img-grid">
                    {#each loadedImages as image, i (image.hash)}
                        {#if i===loadedImages.length-12 && hasMore}
                            <div let:this={tagged} on:intersection={loadMore} use:iobserve={{ once: true, options: {root: scroller, element: tagged, rootMargin: '450px'} }} in:fade={{delay: calculateDelay(i), duration: 250}}>
                                <ImageThumb {image}/>
                            </div>
                        {:else}
                            <div in:fade={{delay: calculateDelay(i), duration: 250}}>
                                <ImageThumb {image}/>
                            </div>
                        {/if}
                    {/each}
                </div>
            {/if}
        {/if}
    {/await}
</div>