import logn from './logn.mjs';

/**
 * @param {number} initial
 * @param {number} [limit=Infinity]
 * @param {number | function(number): number} [step=0]
 * @return {function(number): number}
 */
export const linear = ({initial=0, limit=Infinity, step=0})=>i=>{
    if (typeof step === 'function'){
        console.log(i * step(i) >= limit ? limit : i * step(i));
        return initial + i * step(i) >= limit ? limit : i * step(i);
    }
    return initial + i*step>=limit?limit:i*step;
}

/**
 * @param {number} [limit=Infinity]
 * @param {number} [base=2]
 */
export const exponential = ({limit= Infinity, base= 2})=>linear({limit, step: i=>base**i});

/**
 * @param {number} limit=Infinity
 * @param {number} base=Math.E
 * @return {function(number): number}
 */
export const logarithmic = ({limit=Infinity, base=Math.E})=>linear({limit, step: i=>logn(1.075)(i+1)});