<script>
    import {isUsernameAvailable, register, login} from "../utils/API";
    import {Account} from "../stores.mjs";
    import validateEmail from "../utils/validateEmail.mjs";
    import {MAX_USERNAME_LENGTH, MIN_PASSWORD_LENGTH, STORE_ACCOUNT_SCHEMA} from "../constants";
    import {Link} from "@bjornlu/svelte-router";

    import Modal from "../fragments/Modal.svelte";
    import ErrorModal from "./ErrorModal.svelte";
    import RegisterSuccessModal from "./RegisterSuccessModal.svelte";
    import Switch from "../fragments/Switch.svelte";
    import LoginSuccessModal from "./LoginSuccessModal.svelte";
    import shake from "../utils/animations/shake.mjs";
    import parseJWT from "../utils/parseJWT.mjs";
    import Fa from 'svelte-fa';
    import { faUser, faAt, faKey } from '@fortawesome/free-solid-svg-icons';

    export let close;
    export let isRegisterSelected;

    let modalError, registerSuccessModal, loginSuccessModal;
    let errors = [];
    const form = {
        username: '',
        email: '',
        password: '',
        ToS: false
    }

    let isUsernameValid;
    $: isPasswordValid = form.password.length > MIN_PASSWORD_LENGTH;
    $: isEmailValid = validateEmail(form.email);

    $: {
        errors = [];
        if (!isEmailValid)
            errors.push("That email is invalid");
        if (!isPasswordValid)
            errors.push("The password should be 8 characters or longer");
        if (isRegisterSelected) {
            if (!form.ToS)
                errors.push("You must accept our ToS");
            if (!isUsernameValid)
                errors.push("Username is taken");
        }
        errors = errors;
    }

    const handleSubmit = async () => {
        const response = isRegisterSelected ? await register(form) : await login(form);
        if (!response)
            modalError = true;
        else {
            $Account = {...STORE_ACCOUNT_SCHEMA, jwt: `Bearer ${response.jwt}`, username: parseJWT(response.jwt).username, email: form.email};
            loginSuccessModal = true;
        }
    }
    const validateUsername = async () => {
        if (!form.username) {
            isUsernameValid = false;
            return;
        }
        isUsernameValid = await isUsernameAvailable(form.username);
    }
</script>
<style>
    .input-group {
        border: 2px solid #1cc88a;
        border-radius: 4px;
    }
    .input-group-prepend {
        border-right: 2px solid #1cc88a;
    }
    .input-group-prepend.invalid {
        border: 0!important;
        border-right: 2px solid #e74c3c!important;
    }
    input {
        border: 0!important;
        border-radius: 0!important;
    }
    input:focus {
        border: inherit;
    }
    @media (min-width: 1200px) {
        .container-md {
            max-width: 642px;
        }
    }
    h1 {
        font-size: 1.4rem;
    }
    p {
        min-height: 100%;
    }
    .frow {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
    }
</style>
<Modal open={modalError}>
    <ErrorModal />
</Modal>
<Modal open={registerSuccessModal}>
    <RegisterSuccessModal close={close}/>
</Modal>
<Modal open={loginSuccessModal}>
    <LoginSuccessModal close={close}/>
</Modal>
<div class="container-md p-5 shadow-lg bg-dark">
    <div class="frow">
        <h1>{isRegisterSelected?'Register':'Login'}</h1>
    </div>
    <div class="frow mt-3">
        <Switch bind:checked={isRegisterSelected}/>
    </div>
    <form on:submit|preventDefault={handleSubmit}>
        {#if isRegisterSelected}
            <div class="form-group">
                <label for="name" class:required={!form.username}>Username</label>
                <div class="input-group" class:invalid={!isUsernameValid} class:shake={isUsernameValid===false}>
                    <div class="input-group-prepend" class:invalid={!isUsernameValid}>
                        <div class="input-group-text">
                            <Fa name="icon" icon={faUser} fw/>
                        </div>
                    </div>
                    <input bind:value={form.username} on:blur={validateUsername} class="form-control" id="name" placeholder="AzureDiamond" type="text" maxlength="{MAX_USERNAME_LENGTH}">
                </div>
                <p class:text-warning={form.username?.length>MAX_USERNAME_LENGTH*0.9 && form.username?.length<=MAX_USERNAME_LENGTH} class:text-danger={form.username?.length > MAX_USERNAME_LENGTH} class:shake={form.username?.length > MAX_USERNAME_LENGTH} class="mb-4">{form.username?.length}/{MAX_USERNAME_LENGTH} characters</p>
            </div>
        {/if}
        <div class="form-group">
            <label for="email" class:required={!form.email}>Email</label>
            <div class="input-group" class:invalid={!isEmailValid}>
                <div class="input-group-prepend" class:invalid={!isEmailValid}>
                    <div class="input-group-text">
                        <Fa name="icon" icon={faAt} fw/>
                    </div>
                </div>
                <input bind:value={form.email} class="form-control" id="email" placeholder="user@example.com" type="email">
            </div>
        </div>

        <div class="form-group">
            <label for="password" class:required={!form.password}>Password</label>
            <div class="input-group" class:invalid={!isPasswordValid}>
                <div class="input-group-prepend" class:invalid={!isPasswordValid}>
                    <div class="input-group-text">
                        <Fa name="icon" icon={faKey} fw/>
                    </div>
                </div>
                <input bind:value={form.password} class="form-control" id="password" placeholder="hunter2" type="password">
            </div>
        </div>
        {#if isRegisterSelected}
            <div class="form-group">
                <label for="tos" class="d-block">I accept the <Link to="/ToS"><a href="#" class="d-inline" on:click={close}>ToS</a></Link></label>
                <div class="d-inline" style="cursor:pointer">
                    <input type="checkbox" bind:checked={form.ToS} id="tos">
                </div>
            </div>
        {/if}
        <div class="form-group d-flex justify-content-center">
            {#if errors.length > 0}
                <button data-tooltip="{errors.join('\n')}" on:click={shake} type="button" class="btn btn-danger tooltip-error">{isRegisterSelected?'Register':'Login'}</button>
            {:else}
                <button type="submit" class="btn btn-success">{isRegisterSelected?'Register':'Login'}</button>
            {/if}
        </div>

    </form>
</div>