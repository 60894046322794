<svelte:options immutable={true}/>
<script>
    import {Link} from '@bjornlu/svelte-router';
    import Fa from 'svelte-fa';
    import { faHome, faImages } from '@fortawesome/free-solid-svg-icons';
</script>
<style>
    nav {
        font-size: 1.4rem;
        color: #1a1a1a;
        width: 100%;
        text-align: center;
    }
    nav :global(a){
        font-weight: 900;
        font-size: 1.4rem;
        color: #1cc88a!important;
        width: inherit;
    }
    nav :global(a:not(.link-exact-active)):hover{
        color: rgba(28,200,138,0.5)!important;
    }
    nav :global(.link-exact-active) {
        color: #1a1a1a!important;
        font-weight: 900;
        width: inherit;
        background-color: rgba(28,200,138,0.5);
    }
    div nav:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    div nav:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    :global(.nofocus a:focus){
        box-shadow: unset;
        -webkit-box-shadow: unset;
    }
</style>
<div>
    <nav class="d-flex nofocus">
        <Link to="/feed" aria-label="feed" class="btn">
            <Fa icon={faHome} fw/>
        </Link>
    </nav>
    <nav class="d-flex nofocus">
        <Link to="/" aria-label="home" class="btn">
            <Fa icon={faImages} fw/>
        </Link>
    </nav>
</div>