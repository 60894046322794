<svelte:options immutable={true}/>
<script>
    import {onDestroy} from "svelte";

    import Fa from 'svelte-fa';
    import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

    export let close;

    let remaining = 2.5;
    const out = setTimeout(close, 2500);
    const ticker = setInterval(()=> {
        if (remaining>0)
            remaining-=0.01;
    }, 10);
    onDestroy(()=>{
        clearInterval(ticker);
        clearTimeout(out);
    });
</script>
<style>
    @media (min-width: 1200px) {
        .container-md {
            max-width: 642px;
        }
    }
</style>
<div class="container-md p-5 shadow-lg bg-dark d-flex justify-content-center">
    <Fa icon={faCheckCircle} size="5x" fw class="text-success"/>
    <div class="col">
        <h3>You have been successfully logged in</h3>
        <p>This message will be closed in <p class:text-warning={remaining<1.35} class:text-danger={remaining<0.5}>{remaining.toFixed(2)}s<p></p>
    </div>
</div>