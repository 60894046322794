<svelte:options immutable={true}/>
<script>
    import {route} from '@bjornlu/svelte-router';
    import {AVATAR_STORAGE_DIR, AVATAR_SIZE, AWARD_SIZE, USER_COLORS} from '../constants';
    import {AWARD_ROUTE_PREFIX} from "../constants";
    import {getUserProfile, patchUser} from "../utils/API";
    import {Account, Head} from "../stores";
    import {fade} from "svelte/transition";
    import { setContext } from 'svelte';
    import {navigate} from '@bjornlu/svelte-router';

    import Modal from "../fragments/Modal.svelte";
    import Fa from 'svelte-fa';
    import { faTwitter, faReddit } from "@fortawesome/free-brands-svg-icons";
    import { faCog, faImage, faImages } from "@fortawesome/free-solid-svg-icons";

    let user;

    setContext("props", {
        getUser: () => user
    });

    const dynImport = async ()=>(await import("../modals/NewMessageModal.svelte")).default;


    $: pload = getUserProfile($route.params.username).then(v=>{
        $Head = {
            canonical: window.location.href,
            title: $route.params.username===$Account.username?"Your profile":`${$route.params.username}'s profile`,
            icon: {
                size: AVATAR_SIZE,
                url: `${AVATAR_STORAGE_DIR}/${$route.params.username}.webp`,
                mimetype: "image/webp"
            },
            meta: [
                {name: "description",           content: `Profile of ${$route.params.username}`},
                {name: "rating",                content: "general"},
                {name: "twitter:card",          content: "summary"},
                {name: "twitter:title",         content: `${$route.params.username}'s profile`},
                {name: "twitter:description",   content: `View ${$route.params.username}'s artworks and albums`},
                {name: "twitter:image",         content: `${AVATAR_STORAGE_DIR}/${$route.params.username}.webp`},
                {name: "og:type",               content: "profile"},
                {name: "og:profile:username",   content: $route.params.username},
                {name: "og:title",              content: `${$route.params.username}'s profile`},
                {name: "og:description",        content: `View ${$route.params.username}'s artworks and albums`},
                {name: "og:image:width",        content: AVATAR_SIZE},
                {name: "og:image:height",       content: AVATAR_SIZE},
                {name: "og:image",              content: `${AVATAR_STORAGE_DIR}/${$route.params.username}.webp`},
                {name: "og:image:type",         content: "image/webp"},
            ].concat(v?.social?.twitter?[
                {name: "twitter:creator",       content: v.social.twitter}
            ]:[])
        }
        amIFollowing = v.amIFollowing;
        Head.set({
            canonical: window.location.href,
            title: `${$route.params.username}'s profile`,
            meta: [
                {name: "author",                    content: $route.params.username},
                {name: "rating",                    content: v?.images?.some(i=>i?.tags?.includes('NSFW'))?'adult':'general'},
                {name: "twitter:card",              content: "summary"},
                {name: "twitter:title",             content: `${$route.params.username}'s profile`},
                {name: "twitter:image",             content: `${AVATAR_STORAGE_DIR}/${$route.params.username}.webp`},
                {name: "twitter:image:alt",         content: `${$route.params.username}'s avatar`},
                {name: "og:title",                  content: `${$route.params.username}'s profile`},
                {name: "og:image",                  content: `${AVATAR_STORAGE_DIR}/${$route.params.username}.webp`},
                {name: "og:image:type",             content: "image/webp"},
                {name: "og:image:width",            content: "64"},
                {name: "og:image:height",           content: "64"},
                {name: "og:image:url",              content: window.location.href},
            ].concat(v.bio?[
                {name: "twitter:description",       content: v.bio},
                {name: "og:description",            content: v.bio},
            ]:[]).concat(v?.social?.twitter?[
                {name: "twitter:creator",           content: v.social.twitter},
            ]:[])
        });
        user = {...v, username: $route.params.username};
        return v;
    });
    $: {
        $Head = {
            canonical: window.location.href,
            title: $route.params.username===$Account.username?"Your profile":`${$route.params.username}'s profile`,
            icon: {
                size: AVATAR_SIZE,
                url: `${AVATAR_STORAGE_DIR}/${$route.params.username}.webp`,
                mimetype: "image/webp"
            },
            meta: [
                {name: "description",           content: `Profile of ${$route.params.username}`},
                {name: "rating",                content: "general"},
                {name: "twitter:card",          content: "summary"},
                {name: "twitter:title",         content: `${$route.params.username}'s profile`},
                {name: "twitter:description",   content: `View ${$route.params.username}'s artworks and albums`},
                {name: "twitter:image",         content: `${AVATAR_STORAGE_DIR}/${$route.params.username}.webp`},
                {name: "og:type",               content: "profile"},
                {name: "og:profile:username",   content: $route.params.username},
                {name: "og:title",              content: `${$route.params.username}'s profile`},
                {name: "og:description",        content: `View ${$route.params.username}'s artworks and albums`},
                {name: "og:image:width",        content: AVATAR_SIZE},
                {name: "og:image:height",       content: AVATAR_SIZE},
                {name: "og:image",              content: `${AVATAR_STORAGE_DIR}/${$route.params.username}.webp`},
                {name: "og:image:type",         content: "image/webp"},
            ]
        }
    }
    let amIFollowing;
    let isSendMessageModalVisible;
    let isFollowingModalOpen;
    let isUsernameFaded;
</script>

<style>
    /* ===========
       Profile
     =============*/
    .card-box {
        padding: 20px;
        box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.06), 0 2px 0 0 rgba(0, 0, 0, 0.02);
        -webkit-border-radius: 5px;
        border-radius: 5px;
        -moz-border-radius: 5px;
        background-clip: padding-box;
        margin-bottom: 20px;
        background-color: #1a1a1a;
    }
    .awards {
        border: 2px solid darkgoldenrod;
    }
    .header-title {
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 0.04em;
        line-height: 16px;
        margin-bottom: 8px;
    }
    .nav-tabs {
        background-color: #111;
    }
    .nav.nav-tabs > li.active > * {
        display: inline-block;
        background-color: #1a1a1a;
        border: 0;
    }
    .nav.nav-tabs > li > * {
        color: #228bdf!important;
        cursor: pointer;
        line-height: 50px;
        font-weight: bold;
        padding-left: 20px;
        padding-right: 20px;
        font-family: 'Roboto', sans-serif;
    }
    .nav.nav-tabs > li > *:hover {
        color: #1cc88a !important;
    }
    .tab-content {
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    }
    .nav.nav-tabs {
        border-bottom: 0;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    }
    .nav-tabs > li.active > *,
    .nav-tabs > li.active > *:focus,
    .nav-tabs > li.active > *:hover {
        color: #1cc88a !important;
    }

    .nav.nav-tabs + .tab-content {
        background: #1a1a1a;
        padding: 20px;
    }
</style>

<Modal open={isSendMessageModalVisible} let:close={close}>
    {#await dynImport() then NewMessageModal}
        <NewMessageModal to={$route.params.username} close={()=>{isSendMessageModalVisible=false;close()}}/>
    {/await}
</Modal>

<div class="container overflow-hidden mb-5 h-100">
    <div class="row h-100 overflow-auto pt-4">
        <aside class="col-lg-3 col-md-4">
            <section class="text-center card-box">
                <div class="member-card">
                    <div class="d-flex justify-content-center">
                        <img src="{`${AVATAR_STORAGE_DIR}/${$route.params.username}.webp`}" width="{AVATAR_SIZE}" height="{AVATAR_SIZE}" class="img-circle img-thumbnail" alt="{`${$route.params.username}'s avatar`}">
                    </div>

                    {#await pload}
                        <h4 out:fade on:outroend={()=>isUsernameFaded=true} class="mx-auto my-2 font-weight-bold">{$route.params.username}</h4>
                    {:then user}
                        {#if isUsernameFaded}
                            <h4 in:fade class="mx-auto my-2 font-weight-bold {USER_COLORS[user.color] ?? ''}">{$route.params.username}</h4>
                        {/if}
                        {#if user.bio}
                            <p class="text-muted my-2">{user.bio}</p>
                        {/if}
                    {/await}

                    {#if $Account.jwt && $Account?.username!==$route.params.username}
                        <div class="row">
                            {#if amIFollowing === null}
                                <button type="button" class="btn btn-info disabled btn-sm col-6" disabled><i class="spinner-border spinner-border-sm"></i></button>
                            {:else if amIFollowing}
                                <button on:click={()=>{patchUser({unfollow: $route.params.username}); amIFollowing = false}} type="button" class="btn btn-danger btn-sm col-6">Unfollow</button>
                            {:else}
                                <button on:click={()=>{patchUser({follow: $route.params.username}); amIFollowing = true}} type="button" class="btn btn-success btn-sm col-6">Follow</button>
                            {/if}
                            <button type="button" class="btn btn-success btn-sm col-6" on:click={()=>isSendMessageModalVisible=true}>Message</button>
                        </div>
                    {/if}
                    {#await pload}
                    {:then user}
                        {#if user?.social?.twitter || user?.social?.reddit}
                            <div class="text-left">
                                {#if user?.social?.twitter}
                                    <p class="text-muted font-13">
                                        <Fa icon={faTwitter} fw/>
                                        <span>{user.social.twitter}</span>
                                    </p>
                                {/if}
                                {#if user?.social?.reddit}
                                    <p class="text-muted font-13">
                                        <Fa icon={faReddit} fw/>
                                        <span>{user.social.reddit}</span>
                                    </p>
                                {/if}
                            </div>
                        {/if}
                    {/await}
                </div>
            </section> <!-- end card-box -->

            <section class="card-box awards">
                <h4 class="header-title">Awards</h4>
                {#await pload}
                    <div class="justify-content-center d-flex">
                        <div class="spinner-border"></div>
                    </div>
                {:then user}
                    {#if user?.awards?.length > 0}
                        <ul class="row list-unstyled row-cols-2">
                            {#each user.awards as award}
                                <li class="col-auto">
                                    <img src="{AWARD_ROUTE_PREFIX}/{award.icon}.webp" width="{AWARD_SIZE}" height="{AWARD_SIZE}" alt="{award.name}'s icon">
                                </li>
                                <li class="col-auto">Description of the award</li>
                            {/each}
                        </ul>
                    {:else}
                        <p class="text-muted">This user has no awards</p>
                    {/if}
                {/await}
            </section>
        </aside> <!-- end col -->


        <nav class="col-md-8 col-lg-9 h-100">
            <ul class="nav nav-tabs">
                <li class:active={$route.path.endsWith('/uploads')} on:click={()=>navigate({path: `/u/${$route.params.username}/uploads`}, true)}>
                    <div>
                        <Fa icon={faImage} fw/>
                        <span class="d-none d-sm-inline-block text-uppercase">uploads</span>
                    </div>
                </li>
                <li class:active={$route.path.endsWith('/albums')} on:click={()=>navigate({path: `/u/${$route.params.username}/albums`}, true)}>
                    <div>
                        <Fa icon={faImages} fw/>
                        <span class="d-none d-sm-inline-block text-uppercase">albums</span>
                    </div>
                </li>
                {#if $Account?.username === $route.params.username}
                    <li class:active={$route.path.endsWith('/config')} on:click={()=>navigate({path: `/u/${$route.params.username}/config`}, true)}>
                        <div>
                            <Fa icon={faCog} fw/>
                            <span class="d-none d-sm-inline-block text-uppercase">settings</span>
                        </div>
                    </li>


                    <!--
                    <li class:active={tab==='Wallet'} on:click={()=>tab = "Wallet"}>
                        <div>
                            <Fa icon={faWallet} fw/>
                            <span class="d-none d-sm-inline-block text-uppercase">wallet</span>
                        </div>
                    </li>
                    -->
                {/if}
            </ul>
            <main class="tab-content">
                {#await pload}
                    <div class="justify-content-center d-flex">
                        <div class="spinner-big spinner-border"></div>
                    </div>
                {:then user}
                    <slot/>
                {/await}
            </main>
        </nav> <!-- end col -->
    </div>
    <!-- end row -->
</div>