<svelte:options immutable={true}/>
<script>
    import { Link } from '@bjornlu/svelte-router';
    import {Account} from '../stores.mjs';
    import {THUMBNAIL_ROUTE_PREFIX, USER_COLORS} from "../constants";

    import Fa from 'svelte-fa';
    import { faUser, faEye, faHeart, faComment, faPalette, faLink } from '@fortawesome/free-solid-svg-icons';

    /** @type boolean */
    let isFlipped;

    /** @type {{
     * hash: string,
     * title: string='',
     * nsfw: boolean=,
     * uploader: {
     *     username: string,
     *     color: string=
     * },
     * artist: {
     *     nickname: string
     * }
     * imageViews: number=0,
     * likes: number=0,
     * comments: number=0
    }} */
    export let image;
</script>

<style>
    img {
        border-radius: 4px;
        object-fit: contain;
    }
    .card-body {
        border-radius: 0.2em;
        margin: 0.325em 0;
        padding: 1.25rem;
        position: relative;
    }
    p {
        font-weight: bolder;
    }
    @media screen and (max-width: 600px) {
        .tap-target {
            min-height: 42px;
            display: inline-block;
            line-height: 42px;
        }
    }
    h4{
        font-size: 1.171875rem;
    }
    ul li:not(:first-child) {
        margin-left: 0.15em;
    }

    .flip .card {
        transform: rotateY(180deg);
    }
    .back {
        background-color: #303030;
        z-index: 1;
    }

    .card {
        transform-style: preserve-3d;
        transition: transform 0.4s ease 0s;
        height: 100%;
    }

    .card .front,
    .card .back {
        backface-visibility: hidden;
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 0.2em;
        position: relative;
    }
    .card .front {
        z-index: -1;
    }

    .card .back {
        transform: rotateY(180deg);
        position: absolute;
        top:0;
        left:0;
        z-index: 1;
    }
</style>
<div class="h-100 border-0 d-flex flex-column" class:flip={isFlipped} on:contextmenu|preventDefault={()=>isFlipped=!isFlipped}>
    <div class="card pt-4 pl-2 pr-2">
        <div class="front d-flex flex-column">
            <Link to="/i/{image?.hash}" rel="contents" aria-label={image?.title||"Untitled"} class="aspect-ratio-1">
                <picture>
                    <source loading="lazy" type="image/webp" media="(min-width: 1900px)" srcset={`${THUMBNAIL_ROUTE_PREFIX}/${image?.hash}_512x512.webp`}/>
                    <source loading="lazy" type="image/webp" media="(min-width: 300px)" srcset={`${THUMBNAIL_ROUTE_PREFIX}/${image?.hash}_256x256.webp`}/>
                    <img width="128" height="128" class="card-img-top mb-2" style="{image.nsfw && !$Account.username?'filter: blur(4px)':''}" crossorigin="anonymous" alt="{image?.title || 'Untitled image'}" src={`${THUMBNAIL_ROUTE_PREFIX}/${image?.hash}_128x128.webp`}>
                </picture>
            </Link>
            <div class="card-body d-flex flex-column pb-0 bg-dark">
                <!--<div class="reflection"></div>-->
                <Link class="tap-target" to="/i/{image?.hash}" rel="contents">
                    <h4 class="card-title text-center">{image?.title||"Untitled"}</h4>
                </Link>
                <div class="mt-auto">
                    <div class="d-flex justify-content-center align-items-center tap-target mr-1">
                        <Fa icon={faUser} fw style="margin-right: 0.25rem" class="text-muted"/>
                        <Link to="/u/{image?.uploader?.username}" rel="author">
                            <p class="{`${USER_COLORS[image?.uploader?.color] ?? 'text-muted'}`}">{image?.uploader?.username}</p>
                        </Link>
                    </div>
                    <ul class="list-unstyled justify-content-around mt-2 d-flex" style="gap: 0.1em">
                        <li class="d-flex flex-nowrap align-items-center">{image?.imageViews ?? 0}&nbsp;<Fa icon={faEye} fw class="text-info"/></li>
                        <li class="d-flex flex-nowrap align-items-center">{image?.likes ?? 0}&nbsp;<Fa icon={faHeart} fw class="text-danger"/></li>
                        <li class="d-flex flex-nowrap align-items-center">{image?.comments ?? 0}&nbsp;<Fa icon={faComment} fw class="text-muted"/></li>
                    </ul>
                </div>
            </div>
        </div>
        <Link to="/i/{image?.hash}" rel="contents" style="display: contents" class="cv-auto">
            <div class="back pt-4 pl-2 pr-2 d-flex flex-column align-items-center">
                <h3 class="text-center">{image.title || 'Untitled'}</h3>
                {#if image.description}
                    <p class="pt-4" style="overflow-y: scroll">{image.description}</p>
                {/if}
                <div class="d-flex flex-column align-items-center mt-auto mb-5">
                    <a href="{image.source || '#'}">
                        <Fa icon={faLink} fw class="mr-2"/>Source
                    </a>
                    <span>
                        <Fa icon={faPalette} fw class="mr-2 mt-2"/>{image?.artist?.nickname ?? 'Unknown'}
                    </span>
                </div>
            </div>
        </Link>
    </div>
</div>
