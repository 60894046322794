<svelte:options immutable={true}/>
<script>
    import {FAVICON_URL, FAVICON_SIZE, FAVICON_MIME} from '../constants.js';
    import {Head, Tags} from '../stores.mjs';
    import {Link} from '@bjornlu/svelte-router';
    import {CONTAINS_PARENTHESES_PAIRS, MATCH_ANYTHING_IN_PARENTHESES, MATCH_UP_TO_FIRST_PARENTHESE} from '../utils/regex.mjs';
    import {logarithmic} from '../utils/math/sequence.mjs';
    import {slide} from "svelte/transition";

    import Fa from 'svelte-fa';
    import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

    const filterFn = t=>!CONTAINS_PARENTHESES_PAIRS.test(t);
    const sortByShow = (a, b)=>{
        const tmpa = {name: a.match(MATCH_UP_TO_FIRST_PARENTHESE)[0].trimRight(), show: a.match(MATCH_ANYTHING_IN_PARENTHESES)[0]};
        const tmpb = {name: b.match(MATCH_UP_TO_FIRST_PARENTHESE)[0].trimRight(), show: b.match(MATCH_ANYTHING_IN_PARENTHESES)[0]};
        if (tmpa.show+tmpa.name < tmpb.show+tmpb.name)
            return -1;
        return 1;
    }

    const calculateSlideDelay = logarithmic({base: 1.075, limit: 1550});

    $:pureTags = $Tags.filter(filterFn).sort((a, b)=>a<b?-1:1);
    $:characters = $Tags.filter(t=>!filterFn(t)).sort(sortByShow);
    $:{
        $Head = {
            canonical: window.location.href,
            title: "Tags",
            meta: [
                {name: "description",           content: `Tag list: ${$Tags.join(', ')}`},
                {name: "rating",                content: "adult"},
                {name: "twitter:card",          content: "summary"},
                {name: "twitter:title",         content: "Tag list"},
                {name: "twitter:description",   content: "Browse a myriad of different images"},
                {name: "twitter:image",         content: FAVICON_URL},
                {name: "og:type",               content: "website"},
                {name: "og:title",              content: `Tag list: ${$Tags.join(', ')}`},
                {name: "og:description",        content: "Browse a myriad of different images"},
                {name: "og:image:width",        content: FAVICON_SIZE},
                {name: "og:image:height",       content: FAVICON_SIZE},
                {name: "og:image",              content: FAVICON_URL},
                {name: "og:image:type",         content: FAVICON_MIME},
            ]
        }
    }
</script>
<style>
    header{
        font-family: "Helvetica Neue",serif;
        font-weight: bolder;
        margin-bottom: 1rem;
        font-size: 3rem;
    }
</style>
<div class="col vh-100 p-0 overflow-hidden vw-100">
    <main class="row p-5 row-cols-1 row-cols-md-2 h-100 overflow-auto">
        <section class="col h-100">
            <header>Tags</header>
            <ol class="p-2 list-unstyled overflow-auto col" style="height: calc(100% - 6rem)">
                {#each pureTags as tag, i}
                    <li in:slide={{delay: calculateSlideDelay(i)}}>
                        <Link to="/search/{tag}/0" rel="tag">
                            {tag}
                        </Link>
                    </li>
                {/each}
            </ol>
        </section>
        <section class="col h-100">
            <header>Characters</header>
            <ol class="p-2 list-unstyled overflow-auto col" style="height: calc(100% - 6rem)">
                {#each characters as char, i}
                    <li in:slide={{delay: calculateSlideDelay(i)}}>
                        <Link class="character" to="/search/{char}/0" rel="search">
                            {char}
                        </Link>
                        <Link to="/tag/{char}" aria-label="info">
                            <Fa icon={faInfoCircle} fw class="text-muted position-absolute ml-1"/>
                        </Link>
                    </li>
                {/each}
            </ol>
        </section>
    </main>
</div>