export default function(node, {cond} = {cond: true}) {
    const handleClick = (event)=>{
        const ccond = typeof cond === 'function'?cond():cond;
        if (ccond){
            if (node && !node.contains(event.target) && !event.defaultPrevented){
                node.dispatchEvent(new CustomEvent('clickOutside', node));
            }
        }
    }
    document.addEventListener('click', handleClick, true);
    return {
            destroy(){
                document.removeEventListener('click', handleClick, true);
            }
        }
}