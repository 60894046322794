<svelte:options immutable={true}/>
<script>
    import {IMAGE_ROUTE_PREFIX, THUMBNAIL_ROUTE_PREFIX} from '../constants.js';
    import {Head} from '../stores.mjs';
    import {search} from "../utils/API.mjs";
    import {route} from "@bjornlu/svelte-router";
    import {onDestroy} from "svelte";
    import {linear} from '../utils/math/sequence.mjs';
    import {chunk, eachSequential} from '../utils/math/Array.mjs';
    import {iobserve} from "@shibiii/svelte-iobserve";

    import {fade} from "svelte/transition";
    import ImageThumb from "../fragments/ImageThumb.svelte";

    /** @type Boolean */
    let hasMore = true;
    /** @type Boolean */
    let isAlreadyLoading;

    /** @type String */
    let cursor;
    /** @type String[] */
    let tags;
    /** @type HTMLElement */
    let scroller;
    let loadedItems = [];
    /** @type Promise<void> */
    let pload;
    /** @type boolean */
    let headSet;

    /** @type Promise<void> */
    let renderingPromise = (async ()=>{})();

    const loadItems = async () => {
        isAlreadyLoading = true;
        const response = await search({tags, cursor});
        if (response.cursor === "0")
            hasMore = false;
        cursor = response.cursor;
        renderingPromise = renderingPromise.then(eachSequential(50)(chunk(response.data, 6), vx=>requestAnimationFrame(()=>loadedItems = [...loadedItems, ...vx])));
        isAlreadyLoading = false

        if (!headSet && response?.data?.length>0){
            Head.set({
                canonical: window.location.href,
                title: `Waifu gallery - Search: ${tags.map(decodeURIComponent).join(', ')}`,
                meta: [
                    {
                        name: "description",
                        content: `Found images of ${tags.map(x=>x.toUpperCase()).includes("GENSHIN IMPACT") ? "Genshin waifus" : ''} ${tags.map(decodeURIComponent).join(', ')} among lots of anime themed pictures`
                    },
                    {
                        name: "rating",
                        content: tags.map(decodeURIComponent).map(x=>x.toUpperCase()).includes("NSFW") ? 'adult' : 'general'
                    },
                    {name: "twitter:card", content: "summary"},
                    {name: "twitter:title", content: `Images of ${tags.map(decodeURIComponent).join(', ')}`},
                    {name: "twitter:image", content: `${THUMBNAIL_ROUTE_PREFIX}/${response.data[0].hash}_512x512.webp`},
                    {name: "og:type", content: "article"},
                    {name: "og:title", content: `Images of ${tags.join(', ')}`},
                    {name: "og:image", content: `${IMAGE_ROUTE_PREFIX}/${$route.params.hash}.webp`},
                    {name: "og:image:type", content: "image/webp"},
                ]
            });
            headSet = true;
        }
        return response;
    }

    const calculateDelay = linear({step: 50, initial: 250, limit: 1000});

    const unsub = route.subscribe(t => {
        if (t?.params?.wild) {
            tags = t.params.wild.split('/')?.slice(1, -1);
            cursor = 0;
            loadedItems = [];
            pload = loadItems();
        }
    });

    const loadMore = () => {
        if (isAlreadyLoading)
            return;
        loadItems();
    }
    onDestroy(unsub);
</script>

{#await pload}
    <div class="spinner-border spinner-big m-auto d-block" style="flex-basis: auto"></div>
{:then data}
    <div bind:this={scroller} class="img-grid">
        {#if loadedItems.length === 0}
            <p class="text-center p-5 text-muted">There is nothing to see here</p>
        {:else}
            {#each loadedItems as image, i (image.hash)}
                {#if i===loadedItems.length-12 && hasMore}
                    <div let:this={tagged} on:intersection={loadMore} use:iobserve={{ once: true, options: {root: scroller, element: tagged, rootMargin: '450px'} }} in:fade={{delay: calculateDelay(i), duration: 250}}>
                        <ImageThumb {image}/>
                    </div>
                {:else if i<32}
                    <div in:fade={{delay: calculateDelay(i), duration: 250}}>
                        <ImageThumb {image}/>
                    </div>
                {:else}
                    <ImageThumb {image}/>
                {/if}
            {/each}
        {/if}
    </div>
{/await}